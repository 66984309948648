<header
    class="w-full fixed top-0 z-40 py-[5px] transition duration-500 {{ showBackground() ? 'bg-zinc-900 bg-opacity-90' : '' }}">

    <div (click)="handleLogoClicked()" class="absolute top-2 left-2 overflow-hidden">
        <!-- <div class="ml-12 md:ml-4 w-full max-w-[150px] lg:max-w-[200px] overflow-hidden"> -->
            <svg class="defs-only" width="116.76226" height="29.223769"  version="1.1" id="svg1"
                inkscape:version="1.3 (0e150ed6c4, 2023-07-21)" sodipodi:docname="MyOptyxLogo.svg"
                xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
                xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns="http://www.w3.org/2000/svg"
                xmlns:svg="http://www.w3.org/2000/svg">
                <defs id="defs1" />                
                <symbol id="header-logo" >
                <g inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1"
                    transform="translate(0.24993682,0.24997133)">
                    <text xml:space="preserve"
                        style="font-style:italic;font-variant:normal;font-weight:900;font-stretch:normal;font-size:27.5144px;line-height:0;font-family:'Segoe UI';-inkscape-font-specification:'Segoe UI Heavy Italic';letter-spacing:1.00599px;fill:#f6cd46;stroke:#000000;stroke-width:0.996;stroke-dasharray:none"
                        x="-3.2188804" y="19.816782" id="text1" transform="scale(0.92285049,1.0835991)">
                        <tspan sodipodi:role="line" id="tspan1"
                            style="font-style:italic;font-variant:normal;font-weight:900;font-stretch:normal;font-size:27.5144px;line-height:0;font-family:'Segoe UI';-inkscape-font-specification:'Segoe UI Heavy Italic';letter-spacing:1.00599px;stroke-width:0.996;stroke-dasharray:none"
                            x="-3.2188804" y="19.816782" dx="3.0980732 -1.5057069 0 0 0 0">
                            <tspan style="fill:#cbcbcb;fill-opacity:1" id="tspan2">M</tspan>
                            <tspan style="fill:#cdcdcd;fill-opacity:1" id="tspan3">y</tspan>
                            <tspan id="tspan6" dx="-0.99658304 -2.6214466 -1.3648854 -0.17331877 -0.55245364"
                                style="letter-spacing:1.00599px;stroke-width:0.996;stroke-dasharray:none">Optyx</tspan>
                        </tspan>
                    </text>
                </g>
                </symbol>
            </svg>
            <svg viewBox="0 0 116.76226 29.223769" class="ml-12 md:ml-4 w-full max-w-[150px] md:max-w-[200px] pt-4 md:pt-2">
                <use xlink:href="#header-logo"/>
            </svg>
        <!-- </div> -->
        <!-- <img class="ml-12 md:ml-4 w-full max-w-[150px] lg:max-w-[200px]" src="assets/svg/MyOptyxLogo.svg" alt="logo" /> -->
    </div>

    <div class="absolute top-0 w-full justify-center transform flex py-3">
        <!-- flex display will override hidden (aka display: none) when activated -->
        <div class="hidden md:flex flex-row duration-500 gap-6">
            <app-navbar-item label="Home" href="/" />

            @if (account.subscriptionUser().isSubscriber) {

            <app-navbar-item label="Design" href="/designer" />

            }

            @if (user.showroomUser().isAdmin){

            <app-navbar-item label="Stage" href="/stage" />

            }
            
            <app-navbar-item label="About" href="/home/about" />
        </div>
    </div>

    <div class="flex w-full flex-row justify-between items-center ">

        <!-- <div class="hidden mx-8 w-full lg:flex xl:max-w-[734px]">
            <app-search-bar class="w-full" />
        </div> -->

        <!-- Mobile -->
        <!-- hidden (aka display: none) will override flex display when activated -->
        <div (click)="showMobileMenu()"
            class="flex md:invisible flex-row items-center gap-2 cursor-pointer relative pl-4">
            <ng-icon class="text-3xl transition {{ mobileMenuVisible() ? 'rotate-180' : 'rotate-0' }}"
                name="bootstrapJustify" />
            <app-mobile-menu [visible]="mobileMenuVisible()" />
        </div>
        <!--End Mobile-->

        <div class="flex flex-row gap-4 md:gap-7 items-center md:mr-10 mt-[5px]">

            @if (0 < cartItems()) {
            
                <app-cart-status />
            
            }
            <!-- <div class="text-gray-200 hover:text-gray-300 cursor-pointer transition">
                <ng-icon class="text-white transition" name="bootstrapSearch" />
            </div> -->
            @if (isAuthenticated()) {

            <div class="text-white py-5 cursor-pointer hover:text-gray-300 transition whitespace-nowrap hover:underline z-10">
                <span (click)="showAccountMenu()" class="hidden lg:flex hover:underline cursor-pointer">{{user.showroomUser().emails}}</span>

                <div (click)="showAccountMenu()" class="flex lg:hidden cursor-pointer group relative">
                    <span>
                        <ng-icon class="transition pt-[2px] pl-1" size="20" name="bootstrapPerson" /></span>
                    <!-- Tooltip -->
                    <span
                        class="invisible group-hover:visible md:flex group-hover:opacity-100 transition-opacity bg-gray-800 px-2 border-[1px] border-white text-sm text-gray-100 rounded-md absolute opacity-0 whitespace-nowrap -bottom-[22px] right-0">
                        {{user.showroomUser().emails}}
                    </span>
                </div>
                <app-account-menu [Visible]="accountMenuVisible()" (OnCloseMenu)="handleCloseAccountMenu()" />
            </div>
            
            } @else {

            <div class="text-white py-5 cursor-pointer hover:text-gray-300 transition whitespace-nowrap hover:underline z-10"
                (click)="login()">
                Login
            </div>
            
            }
            <div
                class="text-white pt-5 pb-4 items-center cursor-pointer hover:text-gray-300 transition whitespace-nowrap hover:underline z-10 pr-4">
                <ng-icon class=" transition" name="bootstrapBell" />
            </div>
        </div>

    </div>
    <!-- <div class="lg:hidden px-6 mt-2">
        <app-search-bar />
    </div> -->
</header>