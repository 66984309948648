import { createMongoAbility } from "@casl/ability";
import { AccountState, ISusbscriptionUserSummary, ISubscriptionAccount } from "..";
import { MaskHelper } from "../..";
import { ICaslPermission } from "../../auth/interface/IPermission";
import { Role } from "../../auth/role";
import { IAddress, IGuestUser, IShowroomUser, ISubscriptionUser, RequiredInformationMissing } from "../interface/IUser";


export class SubscriptionUserSummary implements ISusbscriptionUserSummary {

    id = 0;
    roleMask = 0;
    userName = '';
    emails = '';
    accountCount = 0;

    public get isAdmin(): boolean {

        const maskHelper = new MaskHelper(this.roleMask);
        return maskHelper.has(Role.ADMIN);
    }

    public get isSubscriber(): boolean {

        return 0 < this.accountCount;
    }


    constructor(user?: ISusbscriptionUserSummary) {

        if (user) {
            // Copy the IUser properties into this
            Object.keys(user).forEach((key) => {
                (this as any)[key as keyof ISusbscriptionUserSummary] = user[key as keyof ISusbscriptionUserSummary];
            });
        }
    }    

}


/**
 * A Guest User is instantiated by the GuestService when a User with a valid invitation Guid logs in.
 * The Guest User data is managed in the Showroom Db.
 */
export class GuestUser implements IGuestUser {

    accountNumber = '';
    id = 0;
    roleMask = 0;
    userName = '';
    emails = '';
    permissions: ICaslPermission[] = [];

    public get isValid(): boolean { return 0 < this.id }


    constructor(guestUser?: IGuestUser) {

        if (guestUser) {
            
            // Copy the IUser properties into this
            Object.keys(guestUser).forEach((key) => {
                (this as any)[key as keyof IGuestUser] = guestUser[key as keyof IGuestUser];
            });
        }
    }
}


/**
 * The initial MyOptyx User record is created in the Showroom DB and sync'ed with Purchase DB via Integration Events.
 * It only holds basic User data.
 * Showroom Db is the authoritative source for this inital, basic User data.
 */
export class ShowroomUser implements IShowroomUser {

    id: number = 0;
    roleMask: number = 0;
    userName: string = '';
    emails: string = '';
    permissions: ICaslPermission[] = [];

    public get isAdmin(): boolean {

        const maskHelper = new MaskHelper(this.roleMask);

        return maskHelper.has(Role.ADMIN);
    }

    public get isValid(): boolean { return 0 < this.id }


    constructor(user?: IShowroomUser) {

        if (user) {

            // Copy the IUser properties into this
            Object.keys(user).forEach((key) => {

                (this as any)[key as keyof IShowroomUser] = user[key as keyof IShowroomUser];
            });
        }
    }    
}


export class SubscriptionUser implements ISubscriptionUser {

    id = 0;
    roleMask = 0;
    userName = '';
    companyName = '';
    firstName = '';
    lastName = '';
    emails = '';
    address = <IAddress>{};
    accounts: ISubscriptionAccount[] = [];
    permissions: ICaslPermission[] = [];
    subscribedAccounts: ISubscriptionAccount[] = [];
    requiredInformationMissing = RequiredInformationMissing.None;

    ability = createMongoAbility(this.permissions);

    private _maskHelper = new MaskHelper(this.roleMask);

    public get canRead(): boolean {

        return this.isAdmin || this.isReadOnlySubscriber;
    }

    public get canWrite(): boolean {

        return this.isAdmin || this.isActiveSubscriber;
    }

    public get isAdmin(): boolean {

        return this.isValid && this._maskHelper.has(Role.ADMIN);
    }
    
    /**
     * Admin or Active Subscriber status
     */
    public get isActiveSubscriber(): boolean {

        return this.isValid
            && this._maskHelper.has(Role.ADMIN | Role.SUBSCRIBER);
    }

    /**
     * Admin or any Subscriber status
     */
    public get isSubscriber(): boolean {

        return this.isValid
            && this._maskHelper.has(Role.ADMIN | Role.SUBSCRIBER | Role.READONLY_SUBSCRIBER | Role.INACTIVE_SUBSCRIBER);
    }
    
    /**
     * Admin or Active | ReadOnly Subscriber, excludes Inactive Subscribers 
     */
    public get isReadOnlySubscriber(): boolean {

        return this.isValid
            && this._maskHelper.has(Role.ADMIN | Role.SUBSCRIBER | Role.READONLY_SUBSCRIBER);
    }
    
    public get isValid(): boolean { return 0 < this.id }


    constructor(user?: ISubscriptionUser) {

        if (user) {
            
            // Copy the IUser properties into this
            Object.keys(user).forEach((key) => {
                (this as any)[key as keyof ISubscriptionUser] = user[key as keyof ISubscriptionUser];
            });
            
            this._maskHelper = new MaskHelper(this.roleMask);
            this.ability = createMongoAbility(this.permissions);
        }
    }


    public get accountState(): AccountState {

        const maskHelper = new MaskHelper(this.roleMask);

        if (maskHelper.has(Role.ADMIN | Role.SUBSCRIBER)) {
            
            return AccountState.Active;
        }
        if (maskHelper.has(Role.READONLY_SUBSCRIBER)) {
            
            return AccountState.ReadOnly;
        }
        return AccountState.Inactive
    }
}
