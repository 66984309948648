<div class="{{ visible ? 'left-0' : '-left-full' }} fixed top-0 bottom-0 z-30 w-full h-screen transition-all duration-200
            bg-primary p-8">
    <!-- close icon -->
    <div (click)="closeMenu()" class="flex justify-end mb-8 cursor-pointer">
        <ng-icon class="text-3xl transition" name="bootstrapXLg" />
    </div>

    <div class="flex flex-col gap-y-8">
        <div class="flex flex-col gap-y-8">
            <div class="hover:underline">
                <a routerLink="/" routerLinkActive="underline" ariaCurrentWhenActive="page"
                    [routerLinkActiveOptions]="{exact: true}">Home</a>
            </div>

            @if (account.subscriptionUser().isSubscriber) {

            <div class="hover:underline">
                <a routerLink="/designer" routerLinkActive="underline" ariaCurrentWhenActive="page"
                    [routerLinkActiveOptions]="{exact: true}">Design</a>
            </div>

            }

            @if (user.showroomUser().isAdmin) {

            <div class="hover:underline">
                <a routerLink="/stage" routerLinkActive="underline" ariaCurrentWhenActive="page"
                    [routerLinkActiveOptions]="{exact: true}">Stage</a>
            </div>

            }
            
            <div class="hover:underline">
                <a routerLink="/home/about" routerLinkActive="underline" ariaCurrentWhenActive="page"
                    [routerLinkActiveOptions]="{exact: true}">About</a>
            </div>
        </div>
        <!-- <div class="bg-white w-full h-[1px]"></div>
        <div class="flex flex-col gap-y-8">
            <div class="uppercase cursor-pointer font-medium" *ngFor="let type of catalogTypes()">
                <a [routerLink]="['/products', type.id]">{{type.type}}</a>
            </div>
        </div> -->
    </div>
</div>