/**
 * The Showroom DTOs
 * The interfaces
 * The data contracts
 */
import { ActiconPosition, TextureFont } from "projects/my-common/src";
import { HorizontalClipMode, VerticalClipMode } from "projects/my-common/src/scene/image-mask";
import { equals, Vector3Obj, Vector3Tuple } from "projects/my-common/src/util/utils";


export enum BillingStatus { Billable = 1, NonBillable = 2, ClientAcceptance = 3 }
export enum ClippingPlaneType { Square, Triangle, Circle, RightTriangle }
export enum CrudState { NONE, CREATED, UPDATED, DELETED }
export enum EntityStatus { Active = 1, Inactive = 2, Deleted = 3 }
export enum FlipType { None, Horizontal, Vertical, Both }
export enum ImagePropStatus { Default, Assigned }
export enum ObjectPropStatus { Default, Assigned }
/**
 * Prop classification
 */
export enum PropClass {
    /**
     * Available to be pulled from licence pool as needed in Showroom configuration mode.
     */
    Floating,
    /**
     * Staged and adjusted. Always available on the in its staged position ready to be configured.
     */
    Staged
}
export enum PropType { PROP, IMAGE, OBJECT, PLACEHOLDER, VIDEO }
export enum ResourceSortOption { Created, Name }
export enum SpaceProvider { MATTERPORT }
export enum VideoPropStatus { Default, Assigned, Child }


/**
 * Describe an assignable Image Prop.
 */
export interface IAssignImageProp {

    propId: number
    name: string
    aspect: number
    status: ImagePropStatus
    statusDescription: string
}


/**
 * Describe an assignable Object Prop
 */
export interface IAssignObjectProp {

    propId: number
    name: string
    position: Vector3Tuple
    rotation: Vector3Tuple
    status: ObjectPropStatus
    statusDescription: string
}


/**
 * Describe an assignable Video Prop
 */
export interface IAssignVideoProp {

    propId: number
    name: string
    aspect: number
    status: VideoPropStatus
    statusDescription: string
}


export interface IAssignmentBase {

    id: number
    description: string
    eventDesignId: number
    enableCart: boolean
    enableInteraction: boolean
    name: string
}


export interface ICameraPose {

    position: Vector3Obj
    rotation: { x: number, y: number }
}


/**
 * Clipping planes are affixed to the front of the plane to provide flexible clipping options.
 * Square, triangle and circle shapes are supported 
 * As 2D planes intended to remain infront of the plane renderer, they can only be rotated around the z axis.
 * They can also be scaled to help acieve the desired clipping goals.
 * Stencil ref (0 - 255) is configurable to differentiate and prevent clipping other plane renderers behind this one.
 */
export interface IClippingPlane extends ITrackCrudState {

    id: number
    parentPropId: number
    activatePropLevel: boolean
    planeType: ClippingPlaneType
    position: Vector3Tuple
    rotation: number
    scale: Vector3Tuple
}
export function equalsClippingPlane(cp1: IClippingPlane, cp2: IClippingPlane): boolean {

    return cp1.id === cp2.id
        && cp1.parentPropId === cp2.parentPropId
        && cp1.activatePropLevel === cp2.activatePropLevel
        && cp1.planeType === cp2.planeType
        && equals(cp1.position, cp2.position)
        && cp1.rotation === cp2.rotation
        && equals(cp1.scale, cp2.scale);
}
export function equalsClippingPlanes(cps1: IClippingPlane[], cps2: IClippingPlane[]) {

    let isEqual = cps1.length === cps2.length;

    if (isEqual && 0 < cps1.length) {

        for (const cp1 of cps1) {

            const cp2 = cps2.find(cpa => cpa.id === cp1.id);
            if (!cp2 || !equalsClippingPlane(cp1, cp2)) {

                return false;
            }
        }
    }

    return isEqual;
}


/**
 * Associate a Clipping Plane with and Position Adjustment so its application can be defined by player position.
 */
export interface IClippingPlaneAssignment extends ITrackCrudState {

    id: number
    adjustmentId: number
    clippingPlaneId: number
    planeType: ClippingPlaneType
    position: Vector3Tuple
    rotation: number
    scale: Vector3Tuple
}
export function equalsClippingAssignment(cpa1: IClippingPlaneAssignment, cpa2: IClippingPlaneAssignment): boolean {

    return cpa1.id === cpa2.id
        && cpa1.adjustmentId === cpa2.adjustmentId
        && cpa1.clippingPlaneId === cpa2.clippingPlaneId
        && cpa1.planeType === cpa2.planeType
        && equals(cpa1.position, cpa2.position)
        && cpa1.rotation === cpa2.rotation
        && equals(cpa1.scale, cpa2.scale);
}
export function equalsClippingAssignments(cpas1: IClippingPlaneAssignment[], cpas2: IClippingPlaneAssignment[]) {

    let isEqual = cpas1.length === cpas2.length;

    if (isEqual && 0 < cpas1.length) {

        for (const cpa1 of cpas1) {

            const cpa2 = cpas2.find(cpa => cpa.id === cpa1.id);
            if (!cpa2 || !equalsClippingAssignment(cpa1, cpa2)) {

                return false;
            }
        }
    }

    return isEqual;
}


export interface IDesignImage {

    id: number
    eventDesignId: number
    description: string
    fileName: string
    fileSize: number
    uploadFileName: string
    imageUrl: string
}


export interface IDesignImageSummary {

    id: number
    eventDesignId: number
    fileName: string
    imageUrl: string
}


export interface IDesignImageUpload {

    id: number
    eventDesignId: number
    file: File
}


/**
 * The glb file to associate with an Object Prop.
 * Offset, scale and target size vales.
 */
export interface IDesignObject {

    id: number
    eventDesignId: number
    description: string
    fileName: string
    fileSize: number
    uploadFileName: string
    offset: Vector3Tuple
    /**
     * Scale is set at the Design level because it is connected to the Design Object's Glb file.
     */
    scale: Vector3Tuple
    targetSize: Vector3Tuple
    objectUrl: string

    materials: IDesignObjectMaterial[]
}


export interface IDesignObjectMaterial extends IMaterialBase {

    designObjectId: number
}


export interface IDesignObjectMaterialUpload extends IDesignObjectMaterial {

    file: File
}


export interface IDesignObjectUpload {

    id: number
    eventDesignId: number
    file: File
}


export interface IDesignVideo {

    id: number
    eventDesignId: number
    description: string
    fileName: string
    fileSize: number
    uploadFileName: string
    videoUrl: string
    hlsUrl: string
    snapshotFileName: string
    snapshotFileSize: number
    snapshotUrl: string
}


export interface IDesignVideoUpload {

    id: number
    eventDesignId: number
    file: File
}


export interface IEventDesign {

    id: number
    venueEventId: number
    name: string
    status: EntityStatus
    isDefault: boolean
    designImages: IDesignImage[]
    designObjects: IDesignObject[]
    designVideos: IDesignVideo[]
    imageAssignments: IImageAssignment[]
    imagePropOptions: IImagePropOptions[]
    objectAssignments: IObjectAssignment[]
    videoAssignments: IVideoAssignment[]
    videoPropOptions: IVideoPropOptions[]
}


export interface IEventDesignSummary {

    id: number
    venueEventId: number
    name: string
}


export interface IGuestLink {

    id: number
    expires: Date
    invitationGuid: string
    notes: string
    status: EntityStatus
    visits: number
    venueEventId: number
    visitHistory: IVisitHistory[]
}


/**
 * An Image Assignment describes a connection between a Design Image and an Image Prop.
 * It also describes a text overlay for the Image Prop with or without a Design Image.
 */
export interface IImageAssignment extends IAssignmentBase {

    designImageId: number
    imagePropId: number
    font: TextureFont
    fontSize: number
    text: string
    price: number
    stretchToFit: boolean
    suppressSidebarImage: boolean
}


export interface IImportableDesignImages {

    pageSize: number
    pageIndex: number
    count: number
    data: IImportableImageSearchResult[]
}


export interface IImportableDesignObjects {

    pageSize: number
    pageIndex: number
    count: number
    data: IImportableObjectSearchResult[]
}


export interface IImportableDesignVideos {

    pageSize: number
    pageIndex: number
    count: number
    data: IImportableVideoSearchResult[]
}


export interface IMaterialBase {

    id: number
    name: string
    mapFileName: string
    mapFileSize: number
    mapFlip: FlipType
    mapUrl: string
    alphaMapFileName: string
    alphaMapFileSize: number
    alphaMapFlip: FlipType
    alphaMapUrl: string
}
export function equalsMaterial(m1: IMaterialBase, m2: IMaterialBase): boolean {

    return Object.keys(m1).every(key => {

        if ('name' === key) {

            if (m1.name.trim() !== m2.name.trim()) {

                return false
            }
        } else if (m1[key as keyof IMaterialBase] !== m2[key as keyof IMaterialBase]) {

            return false;
        }

        return true;
    });
}


/**
 * Associate a Design Object with an Object Prop.
 * Provide overrides for object position, rotation and materials.
 */
export interface IObjectAssignment extends IAssignmentBase {

    designObjectId: number
    objectPropId: number
    position: Vector3Tuple
    rotation: Vector3Tuple
    scale: Vector3Tuple

    materials: IObjectAssignmentMaterial[]
    priceOptions: IObjectPriceOption[]
}


export interface IObjectAssignmentMaterial extends IMaterialBase {

    objectAssignmentId: number
}
export function equalsObjectAssignmentMaterial(oam1: IObjectAssignmentMaterial, oam2: IObjectAssignmentMaterial): boolean {

    return equalsMaterial(oam1, oam2)
        && oam1.objectAssignmentId === oam2.objectAssignmentId;
}
export function equalsObjectAssignmentMaterials(oams1: IObjectAssignmentMaterial[], oams2: IObjectAssignmentMaterial[]): boolean {

    if (oams1.length !== oams2.length) {

        return false;
    }

    for (let m1 of oams1) {

        const m2 = oams2.find(oam => oam.id === m1.id);
        if (!m2 || !equalsObjectAssignmentMaterial(m1, m2)) {

            return false
        }
    }

    return true;
}
export function newObjectAssignmentMaterialFromDesignObjectMaterial(designObjectMaterial: IDesignObjectMaterial): IObjectAssignmentMaterial {

    return <IObjectAssignmentMaterial>{
        id: 0,
        alphaMapFileName: designObjectMaterial.alphaMapFileName,
        alphaMapFileSize: designObjectMaterial.alphaMapFileSize,
        alphaMapFlip: designObjectMaterial.alphaMapFlip,
        alphaMapUrl: designObjectMaterial.alphaMapUrl,
        mapFileName: designObjectMaterial.mapFileName,
        mapFileSize: designObjectMaterial.mapFileSize,
        mapFlip: designObjectMaterial.mapFlip,
        mapUrl: designObjectMaterial.mapUrl,
        name: designObjectMaterial.name,
        objectAssignmentId: 0
    }
}


export interface IObjectAssignmentMaterialUpload extends IObjectAssignmentMaterial {

    file: File
}


export interface IObjectPriceOption extends IPriceOption {

    objectAssignmentId: number
}
export function equalsObjectPriceOption(opo1: IObjectPriceOption, opo2: IObjectPriceOption): boolean {

    return equalsPriceOption(opo1, opo2)
        && opo1.objectAssignmentId === opo2.objectAssignmentId;
}
export function equalsObjectPriceOptions(opos1: IObjectPriceOption[], opos2: IObjectPriceOption[]): boolean {

    if (opos1.length !== opos2.length) {

        return false;
    }
    for (const opo1 of opos1) {

        const opo2 = opos2.find(opo => opo.id === opo1.id);
        if (!opo2 || !equalsObjectPriceOption(opo1, opo2)) {

            return false
        }
    }
    // To be sure should perform the same comparison starting with opos2

    return true;
}


export interface IPriceOption {

    id: number
    objectAssignmentId: number
    label: string
    price: number
}
export function equalsPriceOption(po1: IPriceOption, po2: IPriceOption): boolean {

    return po1.id === po2.id
        && po1.objectAssignmentId === po2.objectAssignmentId
        && po1.label.trim() === po2.label.trim()
        && po1.price === po2.price;
}


export interface IProp {

    id: number
    venueId: number
    isActive: boolean
    /**
     * Set for staged props to be inherited or overidden.
     * Describes purchase order line item.
     */
    name: string
    position: Vector3Tuple
    propClass: PropClass
    rotation: Vector3Tuple
    scale: Vector3Tuple
    viewPositionId: string

    clippingPlanes: IClippingPlane[]
    positionAdjustments: IPositionAdjustment[]
}


export interface IPurchaseGuestLink {

    id: number
    enableCart: boolean
    expires: Date
    invitationGuid: string
}


export interface IShowroomPosition {

    id: string
    entityId: number
    labelId?: string
    position: Vector3Obj
}


export interface ISpaceProviderDefaultSetting {

    id: number
    spaceProviderId: number
    key: string
    value: string
    description: string
}


/**
 * Override a default setting
 */
export interface ISpaceProviderSetting {

    id: number
    spaceProviderDefaultSettingId: number
    venueId: number
    value: string
}


export interface ITrackCrudState {

    crudState: CrudState
}
// export function isTracksCrudState(target: any): target is ITrackCrudState {

//     return 'crudState' in target;
// }


export interface IVenue {

    id: number
    accountId: number
    spaceProviderId: SpaceProvider
    spaceId: string
    spaceProviderDefaultSettings: ISpaceProviderDefaultSetting[]
    spaceProviderSettings: ISpaceProviderSetting[]
    status: EntityStatus
    name: string
    venueEvents: IVenueEvent[]
    imageProps: IImageProp[]
    objectProps: IObjectProp[]
    videoProps: IVideoProp[]
}


export interface IVenueSummary {

    id: number
    accountId: number
    name: string
    spaceId: string
}


export interface IVenueEvent {

    id: number
    venueId: number
    status: EntityStatus
    isDefault: boolean
    name: string
    eventDesigns: IEventDesign[]
    guestLinks: IGuestLink[]
}


export interface IVenueEventSummary {

    id: number
    venueId: number
    name: string
    isActive: boolean
}


export interface IVideoAssignment extends IAssignmentBase {

    designVideoId: number
    videoPropId: number
    suppressSidebarVideo: boolean
}


export interface IVisitHistory {

    id: number
    visited: Date
}


export interface IImageProp extends IProp {

    aspect: number
    maskFileName: string
    maskUrl: string
}


export interface IImagePropOptions {

    id: number
    backgroundColor: string
    eventDesignId: number
    imagePropId: number
    controlsPosition: ActiconPosition
    controlsMargin: number
    controlsZ: number
    interactionDistance: number
}


export interface IImagePropUpload extends IImageProp {

    file: File
}


/**
 * Not used
 */
export interface IObjectPositionAdjustment {

    id: number
    adjust: Vector3Tuple
    hide: boolean
    objectPropId: number
    positionId: string
    rotate: Vector3Tuple
    scale: Vector3Tuple

    state?: CrudState
}


/**
 * The base, scale is not used with Object Props. 
 * For Object Props, scale is set at the Design Object level.
 */
export interface IObjectProp extends IProp { }


export interface IVideoProp extends IProp {

    aspect: number
    positionAdjustments: IPositionAdjustment[]
    maskFileName: string
    maskUrl: string
}


export interface IPositionAdjustment extends ITrackCrudState {

    id: number
    adjust: Vector3Tuple
    disableDepth: boolean
    hide: boolean
    horizontalMaskMode: HorizontalClipMode
    horizontalMaskWidth: number
    horizontalMaskXOffset: number
    parentPropId: number
    positionId: string
    renderOrder: number
    rotate: Vector3Tuple
    scale: Vector3Tuple
    /** 0 - 255 */
    stencilRef: number
    verticalMaskHeight: number
    verticalMaskMode: VerticalClipMode
    verticalMaskYOffset: number
    maskFileName: string
    maskUrl: string

    clippingAssignments: IClippingPlaneAssignment[]
}

export interface IPositionAdjustmentUpload extends IPositionAdjustment {

    file: File
}


export interface IVideoPropOptions {

    id: number
    eventDesignId: number
    videoPropId: number
    audioDistance: number
    autoPlay: boolean
    autoPlayDistance: number
    backgroundColor: string
    controlsPosition: ActiconPosition
    controlsMargin: number
    controlsZ: number
    interactionDistance: number
    isOrchestrationParent: boolean
    loop: boolean
    orchestrationParentId: number
}


/**
 * Video Props identified as Orchestration parents via their VideoPropOptions per Event Design
 */
export interface IVideoOrchestrationParent {

    description: string
    eventDesignId: number
    videoPropOtionsId: number
    videoPropId: number
}


export interface ISearchResult {

    userId: number
    emails: string
    accountId: number
    accountNumber: string
    venueId: number
    venueName: string
    venueEventId: number
    venueEventName: string
    eventDesignId: number
    eventDesignName: string
    designImageId: number
    fileName: string
    designImageCreated: Date
}


export interface IImportableImageSearchResult {

    userId: number
    emails: string
    accountId: number
    accountNumber: string
    venueId: number
    venueName: string
    venueEventId: number
    venueEventName: string
    eventDesignId: number
    eventDesignName: string
    designImageId: number
    fileName: string
    uploadFileName: string
    created: Date
    imageUrl: string
}


export interface IImportableObjectSearchResult {

    userId: number
    emails: string
    accountId: number
    accountNumber: string
    venueId: number
    venueName: string
    venueEventId: number
    venueEventName: string
    eventDesignId: number
    eventDesignName: string
    designObjectId: number
    fileName: string
    uploadFileName: string
    designObjectCreated: Date
    objectUrl: string
}


export interface IImportableVideoSearchResult {

    userId: number
    emails: string
    accountId: number
    accountNumber: string
    venueId: number
    venueName: string
    venueEventId: number
    venueEventName: string
    eventDesignId: number
    eventDesignName: string
    designVideoId: number
    fileName: string
    uploadFileName: string
    created: Date
    videoUrl: string
    hlsUrl: string
}
/**
 * Determine ability to convert to Design Video
 * @param target 
 */
export function isImportableVideoSearchResult(target: any): target is IImportableVideoSearchResult {

    return 'eventDesignId' in target
        && 'designVideoId' in target
        && 'fileName' in target
        && 'uploadFileName' in target
        && 'videoUrl' in target
        && 'hlsUrl' in target;
}