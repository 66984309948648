import { Injectable, DestroyRef, inject } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Subject, Subscription, filter, takeUntil, tap } from 'rxjs';
import { IdTokenClaimsWithPolicyId } from 'src/app/config.auth';
import { environment } from 'src/environments/environment';
import {
  AccountInfo,
  AuthenticationResult,
  CacheLookupPolicy,
  EventMessage,
  EventType,
  IPublicClientApplication,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  PromptValue,
  RedirectRequest,
  SilentRequest,
  SsoSilentRequest
} from '@azure/msal-browser';
import { ClaimFields, UserData } from 'src/app/core/model/security.model';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';


/**
 * TODO: Figure out how to configure msalAuth to ALWAYS ask which account to use. 
 * Do not default to the currently logged in user unless they ask to stay logged in.
 */
@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  private readonly _destroying$ = new Subject<void>();
  private _destroyRef = inject(DestroyRef);
  private _subscriptions: Subscription[] = [];

  // Observable fired when isAuthorized changes
  private _isAuthorizedSource = new Subject<boolean>();
  isAuthenticatedUpdate$ = this._isAuthorizedSource.asObservable();

  public isAuthenticated: boolean = false;
  public accountData?: AccountInfo;
  public userData: UserData = <UserData>{};
  private _msalInstance: IPublicClientApplication;


  constructor(private readonly msalService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly logger: NGXLogger) {
    
    this._destroyRef.onDestroy(() => this._OnDestroy());
    this._msalInstance = msalService.instance;

    this._msalInstance.initialize()
      .then(() => this.initialize());
  }


  editProfile() {

    const editProfileFlowRequest: RedirectRequest | PopupRequest = {

      authority: environment.b2cPolicies.authorities.signUpSignIn.authority,
      scopes: [],
    };

    this.login(editProfileFlowRequest);
  }


  /**
   * To support SignalR because Msal HttpInterceptor does not intercept SignalR requests.
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md
   * @param scopes 
   * @returns 
   */
  async acquireTokenSilent(scopes: string[]): Promise<string> {

    const account = this._msalInstance.getActiveAccount();

    if (!account) {
      
      return '';
    }
    const accessTokenRequest: SilentRequest = {

      scopes: scopes,
      account: account,
      forceRefresh: false,
      cacheLookupPolicy: CacheLookupPolicy.AccessTokenAndRefreshToken
    }

    const authResult = await this._msalInstance.acquireTokenSilent(accessTokenRequest);
    if (!authResult) {
      
      return '';
    }

    return authResult.accessToken;
  }


  /**
   * TODO: Verify where parameters are defined, how they are used , etc
   * Login requests initiate here. The handling happens in the listeners setup during initialization.
   * @param userFlowRequest
   */
  async login(userFlowRequest?: RedirectRequest | PopupRequest) {
    
    // this._msalInstance.loginRedirect({
    //   scopes: ['openid', 'email'],
    //   prompt: 'create',
    // });

    // return;
    
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#interaction_in_progress
    await this._msalInstance.handleRedirectPromise(); // Make sure nothing else is already in progress.

    if (environment.msalGuardConfig.interactionType === InteractionType.Popup) {

      if (environment.msalGuardConfig.authRequest) {

        await this._msalInstance.loginPopup({ ...environment.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest);
      } else {

        await this._msalInstance.loginPopup(userFlowRequest);
      }
    } else {
      if (environment.msalGuardConfig.authRequest) {

        this.logger.trace(`${window.location}`, environment.msalGuardConfig.authRequest);
        await this._msalInstance.loginRedirect({ ...environment.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
      } else {

        this.logger.trace(`${window.location}`);
        await this._msalInstance.loginRedirect(userFlowRequest);
      }
    }
  }


  logout() {

    if (environment.msalGuardConfig.interactionType === InteractionType.Popup) {

      this.msalService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {

      this.msalService.logoutRedirect();
    }
  }


  /**
   * Setup centralized listeners to handle all events.
   * Handle initial automatic login and refresh tokens if possible.
   * Set initial login state.
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/events.md
   * @returns 
   */
  private async initialize(): Promise<void> {

    // For Angular standalone, do not use the MsalRedirectComponent. Instead, subscribe manually.
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/redirects.md
    this.msalService.handleRedirectObservable().subscribe((result: any) => {
      
      if (null !== result) {
        
        this.logger.error('Redirect', result);
      }
    });

    // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    // this._msalInstance.enableAccountStorageEvents();

    // Status messages
    this._subscriptions.push(
      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status !== InteractionStatus.None),
          tap((status: InteractionStatus) => this.logger.trace(`inProgress status: ${status}`)),
          takeUntil(this._destroying$)
        )
        .subscribe(() => { /* Don't act on InProgress$ status messages. Only act on msalSubject$ authentication result events. */ })
    );

    // Authentication result
    this._subscriptions.push(
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((result: EventMessage) => result.eventType === EventType.ACCOUNT_ADDED || result.eventType === EventType.ACCOUNT_REMOVED),
          tap((result: EventMessage) => this.logger.trace(`msalSubject event: ${result.eventType}`))
        )
        .subscribe((result: EventMessage) => {
          const payload = result.payload as AuthenticationResult;
          this._msalInstance.setActiveAccount(payload.account);
          this.setIsAuthenticated();
        })
    );

    // Authentication result called frequently with each api call requiring authToken as well as the regular login
    this._subscriptions.push(
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((result: EventMessage) => result.eventType === EventType.LOGIN_SUCCESS
            || result.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
            || result.eventType === EventType.SSO_SILENT_SUCCESS),
          tap((result: EventMessage) => this.logger.trace(`msalSubject event: ${result.eventType}`)),
          takeUntil(this._destroying$)
        )
        .subscribe((result: EventMessage) => {
          const payload = result.payload as AuthenticationResult;
          const idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

          // The main expected outcome
          if (payload.authority.startsWith(environment.b2cPolicies.authorities.signUpSignIn.authority)
            || idtoken.acr === environment.b2cPolicies.names.signUpSignIn
            || idtoken.tfp === environment.b2cPolicies.names.signUpSignIn) {
            
            this._msalInstance.setActiveAccount(payload.account);
            this.setIsAuthenticated();
            return;
          }

          /**
           * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
           * from SUSI flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
           * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
           */
          if (idtoken.acr === environment.b2cPolicies.names.signUpSignIn || idtoken.tfp === environment.b2cPolicies.names.signUpSignIn) {

            // retrieve the account from initial sing-in to the app
            const originalSignInAccount = this._msalInstance.getAllAccounts()
              .find((account: AccountInfo) =>
                account.idTokenClaims?.oid === idtoken.oid
                && account.idTokenClaims?.sub === idtoken.sub
                && ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr === environment.b2cPolicies.names.signUpSignIn
                  || (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp === environment.b2cPolicies.names.signUpSignIn)
              );

            const signUpSignInFlowRequest: SsoSilentRequest = {
              authority: environment.b2cPolicies.authorities.signUpSignIn.authority,
              account: originalSignInAccount
            };

            // silently login again with the signUpSignIn policy
            this.logger.warn(`Calling ssoSilent for SSO but browsers are starting to disallow due to third party cookie protections`);
            this.msalService.ssoSilent(signUpSignInFlowRequest);
          }

          /**
           * Below we are checking if the user is returning from the reset password flow.
           * If so, we will ask the user to reauthenticate with their new password.
           * If you do not want this behavior and prefer your users to stay signed in instead,
           * you can replace the code below with the same pattern used for handling the return from
           * profile edit flow (see above ln. 74-92).
           */
          if (idtoken.acr === environment.b2cPolicies.names.signUpSignIn || idtoken.tfp === environment.b2cPolicies.names.signUpSignIn) {

            const signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
              authority: environment.b2cPolicies.authorities.signUpSignIn.authority,
              scopes: [],
              prompt: PromptValue.CREATE // force user to reauthenticate with their new password
            };

            this.login(signUpSignInFlowRequest);
          }
        })
    );

    this._subscriptions.push(
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
          tap((msg: EventMessage) => this.logger.trace('msalSubject event', msg)),
          takeUntil(this._destroying$)
        )
        .subscribe((result: EventMessage) => {
          // Checking for the forgot password error. Learn more about B2C error codes at
          // https://learn.microsoft.com/azure/active-directory-b2c/error-codes
          if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {

            const resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
              authority: environment.b2cPolicies.authorities.signUpSignIn.authority,
              scopes: [],
            };

            this.login(resetPasswordFlowRequest);
          } else {

            this.logger.trace('msalSubject - login failed event', result);
          };
        })
    );

    // Verify Msal state
    // Display existing Accounts in the cache
    const accounts: AccountInfo[] = this._msalInstance.getAllAccounts();
    this.logger.trace(`${accounts.length} Accounts`)
    for (const account of accounts) {

      this.logger.trace(account);
    }

    const accessTokenRequest: SilentRequest = {
      scopes: [],
      // forceRefresh: false,
      // cacheLookupPolicy: CacheLookupPolicy.AccessTokenAndRefreshToken
    }
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md
    if (0 == accounts.length) {  // ssoSilent()

      // const silentRequest = {
      //   scopes: []
      // };
      // this._msalInstance.ssoSilent(silentRequest)
    } else if (1 == accounts.length) { // acquireTokenSilent()

      accessTokenRequest.account = accounts[0];
      this.logger.trace('AcquireTokenSilent for:', accessTokenRequest.account);

      // If we can't renew the token then invoke logout to clear the account from the cache else Msal will get stuck.
      // It will loop seeing the account, trying to renew and failing.
      // Event calls to msalInstance.loginRedirect() will fail because of this looping.
      await this._msalInstance.handleRedirectPromise();
      this._msalInstance.acquireTokenSilent(accessTokenRequest)
        .catch(async (error: any) => {

          this.logger.error('Silent login failed for account:', accounts[0], error);
          // await this._msalInstance.handleRedirectPromise();
          // this.msalService.logoutRedirect();
        });
    } else if (1 < accounts.length) {

      this.logger.trace('Multiple accounts detected. Attempting to silently acquire token on any');
      for (let i = 0; i < accounts.length; i++) {

        accessTokenRequest.account = accounts[i];
        this.logger.trace('AcquireTokenSilent for:', accessTokenRequest.account);
        await this._msalInstance.handleRedirectPromise();
        const result = await this._msalInstance.acquireTokenSilent(accessTokenRequest)
          .catch(async (error: any) => {

            this.logger.error('Silent login failed for account:', accounts[i], error);
            // await this._msalInstance.handleRedirectPromise();
            // this.msalService.logoutRedirect();
          });
        if (result) {
          
          this.logger.trace('Multiple account login result for account', result, accessTokenRequest.account);
          return;
        }
        //this._msalInstance.logoutRedirect();
      }

    }
  }


  /**
   * Called from subscribed Broadcast events. Set up current logged in user.
   * see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  private async setIsAuthenticated() {

    await this._msalInstance.handleRedirectPromise();   // <-- Important - make sure Msal is not currently in the middle of doing something

    const accounts: AccountInfo[] = this._msalInstance.getAllAccounts();

    const isAuthenticated = (accounts.length > 0);
    if (this.isAuthenticated != isAuthenticated) {

      this.isAuthenticated = isAuthenticated;

      // Log some info
      for (const account of accounts) {

        this.logger.trace(account);
      }
      if (this.logger.level == NgxLoggerLevel.TRACE && isAuthenticated) {
        const activeAccount = this._msalInstance.getActiveAccount();
        this.logger.trace('activeAccount:', activeAccount);

        // Diagnostics - log all accounts
        let i = 1;
        for (const account of accounts) {
          this.logger.trace(`account ${i}: ${account?.username}`)
          i++;
        }
        this.logger.trace(`homeAccountId: ${activeAccount?.homeAccountId}`);
        this.logger.trace(`environment: ${activeAccount?.environment}`);
        this.logger.trace(`tenantId: ${activeAccount?.tenantId}`);
        this.logger.trace(`username: ${activeAccount?.username}`);
        this.logger.trace(`localAccountId: ${activeAccount?.localAccountId}`);
        this.logger.trace(`name: ${activeAccount?.name}`);
        this.logger.trace(`idToken: ${activeAccount?.idToken}`);
        this.logger.trace(`nativeAccountId: ${activeAccount?.nativeAccountId}`);
        this.logger.trace(`idTokenClaims: `, activeAccount?.idTokenClaims);
      }

      if (!isAuthenticated) {

        this.logger.trace('Setting accountData to empty');
        this.accountData = <AccountInfo>{};
      } else {

        this.accountData = accounts[0];
        this.logger.trace('accountData', this.accountData);
        if (this.accountData.idTokenClaims) {

          this.userData.userName = this.accountData.idTokenClaims[ClaimFields.USER_NAME] as string;
          this.userData.givenName = this.accountData.idTokenClaims[ClaimFields.GIVEN_NAME] as string;
          this.userData.surName = this.accountData.idTokenClaims[ClaimFields.SUR_NAME] as string;
          this.userData.streetAddress = this.accountData.idTokenClaims[ClaimFields.STREET_ADDRESS] as string;
          this.userData.city = this.accountData.idTokenClaims[ClaimFields.CITY] as string;
          this.userData.state = this.accountData.idTokenClaims[ClaimFields.STATE] as string;
          this.userData.postalCode = this.accountData.idTokenClaims[ClaimFields.POSTAL_CODE] as string;
          this.userData.country = this.accountData.idTokenClaims[ClaimFields.COUNTRY] as string;
          this.userData.emails = this.accountData.idTokenClaims[ClaimFields.EMAILS] as string[];
        }
      }
      this.logger.trace('userData:', this.userData);

      // Broadcast isAuthenticated
      this._isAuthorizedSource.next(this.isAuthenticated);
    }
  }


  private _OnDestroy(): void {
    
    this._subscriptions.forEach(s => s.unsubscribe());
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }


}
