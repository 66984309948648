import { DestroyRef, Injectable, effect, inject, signal } from '@angular/core';
import { Subject, Subscriber, firstValueFrom } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { ConfigurationService } from '../configuration/configuration.service';
import { DataService } from '../data/data.service';
import { UserService } from './user.service';
import { Observable } from 'rxjs/internal/Observable';
import { map, switchMap, tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import {
  SubscriptionUserSummary, ISusbscriptionUserSummary, IImageProp, IInvoice, INewSubscriptionAccount, IObjectProp, IShowroomAccount, IShowroomAccountStat, ISubscriberInvitationLink,
  ISubscriptionAccount, ISubscriptionUser, IVenue, IVenueSummary, IVideoProp, ShowroomAccount, SubscriptionAccount, SubscriptionUser, ShowroomUser
} from 'projects/my-common/src/model';
import { deepCopy } from 'projects/mp-core/src/lib/util';
import { URL_BASE } from 'src/environments/environment';
import { ImageProp, ObjectProp, VideoProp } from 'projects/my-common/src/model';
import { PURCHASE_ENDPOINT, SHOWROOM_ENDPOINT } from 'src/environments/interfaces/IEnvironment';
import { MaskHelper } from 'projects/my-common/src';
import { GuestService } from '../venue/guest.service';

/**
 * When login is detected from UserService, Account Summaries that the User can access are pulled from the server.
 * 
 * An arbitrary Account is selected from the Summary Accounts to requst the SubscriptionUser from the server. 
 *
 * The current SubscriptionAccount defines a SubscriptionUser with Role managed on the server.
 * TODO: If User has multiple Accounts allow them to select (save) the current/default account.
 */

/**
 * Dependency: UserService
 * Handles Account queries from both Purchase and Showoom
 * Uses AccountId when referencing Purchase/Subscription Accounts (authoritative source)
 * Uses AccountNumber when referencing  Showroom Accounts (dependent source)
 * Does MyOptyx User have an Account 
 * If yes, is the MyOptyx User a Subscriber 
 * Get Accounts for the User 
 * Get Account status 
 * Get Venues
 * etc.. 
 */
@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private readonly _destroying$ = new Subject<void>();
  private _destroyRef!: DestroyRef;
  private _showroomAccountsUrl = '';
  private _subscriptionAccountsUrl = '';
  private _subscriptionsUrl = '';
  private _subscriptionUsersUrl = '';
  private _venuesUrl = '';
  private _imagePropsUrl = '';
  private _objectPropsUrl = '';
  private _videoPropsUrl = '';
  private _subscriberInvitationsUrl = '';
  private _rxjsSubscriptions: Subscription[] = [];
  private _subscriptionAccounts: ISubscriptionAccount[] = [];

  private readonly showroomAccountSignal = signal(new ShowroomAccount());
  readonly showroomAccount = this.showroomAccountSignal.asReadonly();
  public get currentShowroomAccount(): IShowroomAccount | undefined {

    return this.showroomAccount().isValid ? this.showroomAccount() : undefined;
  }
  private set currentShowroomAccount(v: IShowroomAccount | undefined) {

    this.showroomAccountSignal.set(new ShowroomAccount(v));
  }

  private _gettingCurrentShowroomAccount = false;     // concurrency flag
  private _currentShowroomAccountQueue: Subscriber<IShowroomAccount | undefined>[] = []; // concurrency queue
  readonly currentShowroomAccount$ = new Observable<IShowroomAccount | undefined>((observer) => {

    if (this.showroomAccount().isValid) {

      observer.next(this.showroomAccount());
      return;
    }
    // Dependency check
    if (!this.subscriptionAccount().isValid) {

      this.logger.trace('Subscription Account is not valid');
      observer.next(undefined);
      return;
    }
    this._currentShowroomAccountQueue.push(observer);
    if (this._gettingCurrentShowroomAccount) {

      return;
    }
    this._gettingCurrentShowroomAccount = true;

    let subscription: Subscription | undefined;
    const unsubscribe = () => subscription?.unsubscribe();
    subscription = this.getShowroomAccount(this.subscriptionAccount().accountNumber)
      .subscribe((account) => {

        if (account) {

          this.logger.trace('Setting Showroom Account. Includes top level Venues.', account);
          this.currentShowroomAccount = account;
          this._currentShowroomAccountQueue.forEach(o => o.next(account));
        } else {

          this.currentShowroomAccount = undefined;
          this._currentShowroomAccountQueue.forEach(o => o.next(undefined));
        }
        this._currentShowroomAccountQueue = [];
        this._gettingCurrentShowroomAccount = false;

        unsubscribe();
      });
  });


  /**
   * To maintain consistency, should only be set by currentSubscriptionAccount setter.
   */
  private readonly subscriptionAccountSignal = signal(new SubscriptionAccount())
  readonly subscriptionAccount = this.subscriptionAccountSignal.asReadonly();
  public get currentSubscriptionAccount(): SubscriptionAccount | undefined {

    return this.subscriptionAccount().isValid ? this.subscriptionAccount() : undefined;
  }
  private set currentSubscriptionAccount(v: ISubscriptionAccount | undefined) {

    this.subscriptionAccountSignal.set(new SubscriptionAccount(v));
    this.logger.trace('Subscription account update', v);
    
    // When Subscription Account changes, then Subscription User and Showroom Account need to be reset.
    this.subscriptionUserSignal.set(new SubscriptionUser());
    this.showroomAccountSignal.set(new ShowroomAccount());

    if (!this.subscriptionAccount().isValid) {

      return;
    }

    // Fire async processes to update Subscription User and Showroom Account.
    this.validateSubscriptionUser();
    this.validateShowroomAccount()
  }

  private _gettingSubscriptionAccount = false;     // concurrency flag
  private _subscriptionAccountQueue: Subscriber<ISubscriptionAccount | undefined>[] = []; // concurrency queue
  readonly currentSubscriptionAccount$ = new Observable<ISubscriptionAccount | undefined>((observer) => {

    if (this.subscriptionAccount().isValid || this.guestService.guestUser().isValid) {

      observer.next(this.subscriptionAccount());
      return;
    }
    // Dependency check
    if (!this.userService.showroomUser().isValid) {

      this.logger.trace('User is not valid');
      observer.next(undefined);
      return;
    }
    this._subscriptionAccountQueue.push(observer);
    if (this._gettingSubscriptionAccount) {

      return;
    }
    this._gettingSubscriptionAccount = true;

    let subscription: Subscription | undefined;
    const unsubscribe = () => subscription?.unsubscribe();
    subscription = this.getSubscriptionAccountSummaries()
      .subscribe((accounts) => {

        this._subscriptionAccounts = accounts;
        if (0 < accounts.length) {

          this.logger.trace('setting DEFAULT currentSubscriptionAccount', accounts[0]);
          this.logger.trace('change default Subscription Account selection logic here (e.g. prioritize Active Subscriptions)');
          this.currentSubscriptionAccount = accounts[0];
          this._subscriptionAccountQueue.forEach(o => o.next(accounts[0]));
        }
        else {

          this.currentSubscriptionAccount = undefined;
          this._subscriptionAccountQueue.forEach(o => o.next(undefined));
        }
        this._subscriptionAccountQueue = [];
        this._gettingSubscriptionAccount = false;

        unsubscribe();
      });
  });


  private readonly subscriptionUserSignal = signal(new SubscriptionUser());
  readonly subscriptionUser = this.subscriptionUserSignal.asReadonly();

  private _gettingCurrentSubscriptionAccountUser = false;     // concurrency flag
  private _currentSubscriptionAccountUserQueue: Subscriber<SubscriptionUser>[] = []; // concurrency queue
  readonly currentSubscriptionAccountUser$ = new Observable<SubscriptionUser>((observer) => {

    if (this.subscriptionUser().isValid) {

      observer.next(this.subscriptionUser());
      return;
    }
    // Dependency check
    if (!this.subscriptionAccount().isValid) {

      this.logger.trace('Subscription Account is not valid');
      this.subscriptionUserSignal.set(new SubscriptionUser());
      observer.next(this.subscriptionUser());
      return;
    }
    this._currentSubscriptionAccountUserQueue.push(observer);
    if (this._gettingCurrentSubscriptionAccountUser) {

      return;
    }
    this._gettingCurrentSubscriptionAccountUser = true;

    this.logger.trace('awaiting getSubscriptionMe');
    let subscription: Subscription | undefined;
    const unsubscribe = () => subscription?.unsubscribe();
    subscription = this.getSubscriptionMe().subscribe({

      next: (subscriptionAccountUser) => {

        this.logger.trace('setting currentSubscriptionAccountUser', subscriptionAccountUser);
        if (this.userService.showroomUser().isAdmin) {

          // Promote to admin
          subscriptionAccountUser.roleMask = new MaskHelper(subscriptionAccountUser.roleMask).add(this.userService.showroomUser().roleMask).get();
        }
        this.subscriptionUserSignal.set(subscriptionAccountUser);
        this._gettingCurrentSubscriptionAccountUser = false;
        this._currentSubscriptionAccountUserQueue.forEach(o => o.next(this.subscriptionUser()));
        this._currentSubscriptionAccountUserQueue = [];

        unsubscribe();
      },
      error: (error) => {

        this.subscriptionUserSignal.set(new SubscriptionUser());
        this._currentSubscriptionAccountUserQueue.forEach(o => o.next(this.subscriptionUser()));
        this._currentSubscriptionAccountUserQueue = [];
        this._gettingCurrentSubscriptionAccountUser = false;

        unsubscribe();
      }
    })
  });


  private _isReady: boolean = false;
  get isReady(): boolean {

    return this._isReady;
  }
  // observable that is fired when urls are set
  private readonly _urlsSetSource = new Subject<any>();
  private readonly urlsSet$ = this._urlsSetSource.asObservable();
  private _gettingReady = false;
  private _whenReadyQueue: Subscriber<unknown>[] = [];
  readonly whenReady$ = new Observable((observer) => {

    if (this._isReady) {

      observer.next();
      return;
    }

    this._whenReadyQueue.push(observer);
    if (!this._gettingReady) {

      this._gettingReady = true;
      let subscription: Subscription | undefined;
      const unsubscribe = () => subscription?.unsubscribe();
      subscription = this.urlsSet$.subscribe(() => {

        this._whenReadyQueue.forEach(o => o.next());
        this._whenReadyQueue = [];
        this._gettingReady = false;

        unsubscribe();
      });
    }
  });


  constructor(private readonly configurationService: ConfigurationService,
    private readonly dataService: DataService,
    private readonly userService: UserService,
    private readonly guestService: GuestService,
    private readonly logger: NGXLogger) {

    this.setUrls();
    this._destroyRef = inject(DestroyRef);
    this._destroyRef.onDestroy(() => this._OnDestroy());

    // When Showroom User changes re-load Subscription Accounts
    effect(() => this.validateSubscriptionAccount(userService.showroomUser()));
  }


  /**
   * @param eventVenue 
   * @returns 
   */
  copyVenueToAccount(venueId: number, accountId: number): Observable<IVenue> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venuesUrl}/copy/${venueId}/${accountId}`

          return this.dataService.put<IVenue>(url, {})
            .pipe(tap((response: IVenue) => this.logger.trace('copied venue', deepCopy(response))));
        })
      );
  }


  /**
   * Administratively create an Account for a User
   * @param account 
   * @returns 
   */
  createAccount(account: INewSubscriptionAccount, setCurrent: boolean = true): Observable<ISubscriptionAccount> {

    return this.whenReady$
      .pipe(
        switchMap(x => {
          return this.dataService.post<ISubscriptionAccount>(this._subscriptionsUrl, account)
            .pipe(
              tap((response: ISubscriptionAccount) => {

                this.logger.trace('subscription account', deepCopy(response));
                const existingAccountIndex = this._subscriptionAccounts.findIndex(a => a.id == response.id);
                if (0 > existingAccountIndex) {

                  this._subscriptionAccounts.push(response);
                } else {

                  this._subscriptionAccounts[existingAccountIndex] = response;
                }
                if (setCurrent && this.subscriptionAccount().id !== response.id) {

                  this.currentSubscriptionAccount = response;
                }
              })
            );
        })
      );
  }


  /**
   * @param eventVenue 
   * @returns 
   */
  createSubscriberInvitationLink(subscriberInvitation: ISubscriberInvitationLink): Observable<ISubscriberInvitationLink> {

    return this.whenReady$
      .pipe(
        switchMap(x => {
          return this.dataService.post<ISubscriberInvitationLink>(this._subscriberInvitationsUrl, subscriberInvitation)
            .pipe(
              tap(async (response: ISubscriberInvitationLink) => {

                this.logger.trace('subscriber invitation link', deepCopy(response));
                this.logger.trace('reloading SubscriptionAccount');
                await this.reloadSubscriptionAccount();
              })
            );
        })
      );
  }


  /**
   * @param eventVenue 
   * @returns 
   */
  createVenue(venue: IVenue): Observable<IVenue> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          return this.dataService.post<IVenue>(this._venuesUrl, venue)
            .pipe(
              tap((response: IVenue) => this.logger.trace('new venue', deepCopy(response)))
            )
        })
      );
  }


  deleteVenue(venueId: number): Observable<IVenue> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venuesUrl}/${venueId}`

          return this.dataService.delete<IVenue>(url, {})
            .pipe(
              tap((response: IVenue) => this.logger.trace('deleted venue', response))
            )
        })
      );
  }


  getAccountUserSummaries(): Observable<SubscriptionUserSummary[]> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._subscriptionUsersUrl}/summaries`;

          return this.dataService.get<ISusbscriptionUserSummary[]>(url)
            .pipe(
              map((response: ISusbscriptionUserSummary[]) => response.map(u => new SubscriptionUserSummary(u)))
            )
        })
      );
  }


  getSubscriptionUser(userEmails: string): Observable<SubscriptionUser> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._subscriptionUsersUrl}/${userEmails}`;

          return this.dataService.get<ISubscriptionUser>(url)
            .pipe(
              map((response: ISubscriptionUser) => {

                this.logger.trace('Subscription user', response);
                return new SubscriptionUser(response);
              })
            )
        })
      );
  }


  getShowroomAccount(accountNumber: string): Observable<IShowroomAccount> {

    return this.whenReady$
      .pipe(
        switchMap(x => {
          const url = `${this._showroomAccountsUrl}/${accountNumber}`;

          return this.dataService.get<IShowroomAccount>(url)
            .pipe(
              tap((response: IShowroomAccount) => this.logger.trace('Showrooom account', deepCopy(response)))
            )
        })
      );
  }


  /**
   * For the currently logged in User
   * @returns 
   */
  getShowroomAccounts(): Observable<IShowroomAccount[]> {

    return this.whenReady$
      .pipe(
        switchMap(x => {
          return this.dataService.get<IShowroomAccount[]>(this._showroomAccountsUrl)
            .pipe(
              tap((response: IShowroomAccount[]) => this.logger.trace('Showroom accounts', deepCopy(response)))
            )
        })
      );
  }


  /**
   * For the currently logged in User
   * @returns 
   */
  getShowroomAccountStats(accountNumber: string): Observable<IShowroomAccountStat[]> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._showroomAccountsUrl}/${accountNumber}/stats`;

          return this.dataService.get<IShowroomAccountStat[]>(url)
            .pipe(
              tap((response: IShowroomAccountStat[]) => this.logger.trace('account stats', response))
            )
        })
      );
  }


  getImageProp(imagePropId: number): Observable<ImageProp> {

    return this.whenReady$
      .pipe(
        switchMap(x => {
          const url = `${this._imagePropsUrl}/${imagePropId}`;

          return this.dataService.get<IImageProp>(url)
            .pipe(
              map((response: IImageProp) => new ImageProp(response))
            )
        })
      );
  }


  getImageProps(venueId: number): Observable<ImageProp[]> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._imagePropsUrl}/venue/${venueId}`;

          return this.dataService.get<IImageProp[]>(url)
            .pipe(
              map((response: IImageProp[]) => response.map(ip => new ImageProp(ip)))
            )
        })
      );
  }


  getImagePropReferenceCount(imagePropId: number): Observable<number> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._imagePropsUrl}/references/count/${imagePropId}`;

          return this.dataService.get<number>(url)
            .pipe(
              tap((response: number) => this.logger.trace('Image Prop reference count', response))
            )
        })
      );
  }


  getInvoice(invoiceId: number): Observable<IInvoice> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._subscriptionsUrl}/invoice/${invoiceId}`;

          return this.dataService.get<IInvoice>(url)
            .pipe(
              tap((response: IInvoice) => this.logger.trace('Invoice', response))
            )
        })
      );
  }


  getInvoices(accountId: number): Observable<IInvoice[]> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._subscriptionsUrl}/invoices/${accountId}`;

          return this.dataService.get<IInvoice[]>(url)
            .pipe(
              tap((response: IInvoice[]) => this.logger.trace('Invoices', response))
            )
        })
      );
  }


  getObjectProp(objectPropId: number): Observable<ObjectProp> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._objectPropsUrl}/${objectPropId}`;

          return this.dataService.get<IObjectProp>(url)
            .pipe(
              map((response: IObjectProp) => new ObjectProp(response))
            )
        })
      );
  }


  getObjectPropReferenceCount(objectPropId: number): Observable<number> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._objectPropsUrl}/references/count/${objectPropId}`;

          return this.dataService.get<number>(url)
            .pipe(
              tap((response: number) => this.logger.trace('Object Prop reference count', response))
            )
        })
      );
  }


  getSubscriberInvitationLink(subscriberInvitationLinkId: number): Observable<ISubscriberInvitationLink> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._subscriberInvitationsUrl}/${subscriberInvitationLinkId}`;

          return this.dataService.get<ISubscriberInvitationLink>(url)
            .pipe(
              tap((response: ISubscriberInvitationLink) => this.logger.trace('Subscriber invitation link', deepCopy(response)))
            )
        })
      );
  }


  /**
   * Get currently logged in User with Subscription permissions applied to roleMask
   * @returns SubscriptionUser
   */
  getSubscriptionMe(): Observable<SubscriptionUser> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._subscriptionUsersUrl}/account/${this.subscriptionAccount().id}/me`;
          //const url = `${this._subscriptionsUrl}/${this.subscriptionAccount().id}/me`;

          return this.dataService.get<ISubscriptionUser>(url)
            .pipe(
              map((user) => {
                this.logger.trace('Subscription me', user);
                return new SubscriptionUser(user);
              })
            )
        })
      );
  }


  getSubscriptionAccount(accountId: number): Observable<ISubscriptionAccount> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._subscriptionsUrl}/${accountId}`;

          return this.dataService.get<ISubscriptionAccount>(url)
            .pipe(
              tap((response: ISubscriptionAccount) => this.logger.trace('Subscription account', deepCopy(response)))
            )
        })
      );
  }


  getSubscriptionAccountByAccountNumber(accountNumber: string): Observable<ISubscriptionAccount> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._subscriptionsUrl}/n/${accountNumber}`;

          return this.dataService.get<ISubscriptionAccount>(url)
            .pipe(
              tap((response: ISubscriptionAccount) => this.logger.trace('Subscription account', deepCopy(response)))
            )
        }));
  }


  /**
   * For the currently logged in User
   * @returns 
   */
  getSubscriptionAccountSummaries(): Observable<ISubscriptionAccount[]> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._subscriptionAccountsUrl}/summary`;

          return this.dataService.get<ISubscriptionAccount[]>(url)
            .pipe(
              tap((response: ISubscriptionAccount[]) => this.logger.trace('Subscription accounts', deepCopy(response)))
            )
        })
      );
  }


  /**
   * Venues with ImageProp and PositionAdjustments for the specified SpaceProvider
   * @param spaceProviderId 
   * @returns 
   */
  getVenues(spaceProviderId: number): Observable<IVenue[]> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          let url = `${this._venuesUrl}/spaceProvider/${spaceProviderId}`;

          return this.dataService.get<IVenue[]>(url)
            .pipe(
              tap((response: IVenue[]) => { })
            )
        })
      );
  }


  getVenueSummaries(accountNumber: string): Observable<IVenueSummary[]> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venuesUrl}/summaries/${accountNumber}`;

          return this.dataService.get<IVenueSummary[]>(url);
        })
      );
  }


  getVenueSummary(venueId: number): Observable<IVenueSummary> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._venuesUrl}/summaries/venue/${venueId}`;

          return this.dataService.get<IVenueSummary>(url);
        })
      );
  }


  getVideoProp(videoPropId: number): Observable<VideoProp> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._videoPropsUrl}/${videoPropId}`;

          return this.dataService.get<IVideoProp>(url)
            .pipe(
              map((response: IVideoProp) => new VideoProp(response))
            )
        })
      );
  }


  getVideoPropReferenceCount(videoPropId: number): Observable<number> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._videoPropsUrl}/references/count/${videoPropId}`;

          return this.dataService.get<number>(url)
            .pipe(
              tap((response: number) => this.logger.trace('Video Prop reference count', response))
            )
        })
      );
  }


  isValidInvitationGuid(invitationGuid: string): Observable<boolean> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._subscriptionsUrl}/v/${invitationGuid}`;

          return this.dataService.get<boolean>(url)
            .pipe(
              tap((response: boolean) => this.logger.trace('Is valid invitation', response))
            )
        })
      );
  }


  redeemInvitationGuid(invitationGuid: string): Observable<string> {
    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._subscriptionsUrl}/redeem?silg=${invitationGuid}`;

          // Special handling needed for repsonses that are just strings
          return this.dataService.post<string>(url, null)
            .pipe(
              map(response => new String(response).toString())
            )
        })
      );
  }


  /**
   * Call when account or subscriber state changes.
   * As if the user just logged in.
   */
  async reloadSubscriptionAccount(attemptToPreserveCurrentActive = true) {

    // Accounts should be reloaded to reflect change in subscribers, invitation links, etc
    const temp = this.subscriptionAccount();
    this.currentSubscriptionAccount = undefined;
    this._subscriptionAccounts = [];
    await this.validateSubscriptionAccount(this.userService.showroomUser());
    if (0 < temp.id && attemptToPreserveCurrentActive) {

      this.logger.trace(`Setting active account: ${temp.accountNumber}`);
      await this.setActiveAccount(temp.accountNumber);
    }
  }


  /**
   * Remove Subscriber (not AccountOwner) from Account
   * @param subscriber
   * @param accountId
   * @returns 
   */
  removeSubscriberFromAccount(subscriber: ISubscriptionUser, accountId: number): Observable<ISubscriptionUser> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._subscriptionsUrl}/subscribers/${subscriber.emails}/${accountId}`;

          return this.dataService.put<ISubscriptionUser>(url, {})
            .pipe(
              tap(async (response: ISubscriptionUser) => {

                this.logger.trace('Removed subscription user', deepCopy(response));
                // Account subscriber state changed. Reload.
                this.logger.trace('Reloading subscription account');
                await this.reloadSubscriptionAccount();
              })
            )
        })
      );
  }


  /**
   * Subscription accounts are bound to the logged in user.
   * Admins can add any account to their subscription accounts collection.
   * @param accountNumber 
   * @returns 
   */
  async setActiveAccount(accountNumber: string) {

    if (!accountNumber || 16 !== new String(accountNumber).length) {

      this.logger.error(`invalid account number: ${accountNumber}`);
      return;
    }
    if (this.subscriptionAccount().accountNumber === accountNumber) {

      // Account number already active
      return;
    }

    // Check accounts cached for current user.
    this.logger.trace(`Checking existing accounts for account number: ${accountNumber}`, this._subscriptionAccounts);
    let targetAccount = this._subscriptionAccounts.find(sa => sa.accountNumber === accountNumber);
    if (targetAccount) {

      this.logger.trace(`Setting account number: ${accountNumber} from cached accounts.`, targetAccount);
      this.currentSubscriptionAccount = targetAccount;
      return;
    }

    // Reload cached accounts in case user has just redeemed invitation and is a new subscriber on account.
    // Disable reloadSubscriptionAccount "preserveCurrentAccount" behavior which calls this "setActiveAccount" method again.
    this.logger.trace('Reloading Subscription account without trying to preserve current active account');
    await this.reloadSubscriptionAccount(false);
    this.logger.trace(`Accounts updated, verifying account number: ${accountNumber}.`, this._subscriptionAccounts);
    targetAccount = this._subscriptionAccounts.find(sa => sa.accountNumber === accountNumber);
    if (targetAccount) {

      this.logger.trace(`Setting account number: ${accountNumber} from updated accounts.`, targetAccount);
      this.currentSubscriptionAccount = targetAccount;
      return;
    }

    // Apply admin user override
    if (this.userService.showroomUser().isAdmin) {

      const account = await firstValueFrom(this.getSubscriptionAccountByAccountNumber(accountNumber));
      if (account) {

        this.logger.trace(`Setting account number: ${accountNumber} as admin user.`, account);
        this._subscriptionAccounts.push(account);
        this.currentSubscriptionAccount = account;
      } else {

        this.logger.warn(`Error retrieving account number: ${accountNumber}.`);
      }
    }
  }


  private async setUrls(): Promise<void> {

    if (!this.configurationService.isReady) {

      await firstValueFrom(this.configurationService.whenReady$);
    }

    this._subscriptionAccountsUrl = `${URL_BASE.PURCHASE}/${SHOWROOM_ENDPOINT.Account}`;
    this._showroomAccountsUrl = `${URL_BASE.SHOWROOM}/${SHOWROOM_ENDPOINT.Account}`;
    this._subscriptionsUrl = `${URL_BASE.PURCHASE}/${PURCHASE_ENDPOINT.Subscription}`;
    this._venuesUrl = `${this._showroomAccountsUrl}/venues`;
    this._imagePropsUrl = `${this._showroomAccountsUrl}/imageProps`;
    this._objectPropsUrl = `${this._showroomAccountsUrl}/objectProps`;
    this._videoPropsUrl = `${this._showroomAccountsUrl}/videoProps`;
    this._subscriberInvitationsUrl = `${this._subscriptionsUrl}/subscriberInvitations`;
    this._subscriptionUsersUrl = `${URL_BASE.PURCHASE}/${PURCHASE_ENDPOINT.User}`;

    this._isReady = true;
    this._urlsSetSource.next(true);
  }


  updateSubscriptionAccount(account: ISubscriptionAccount, makeCurrent: boolean = false): Observable<ISubscriptionAccount> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          return this.dataService.put<ISubscriptionAccount>(this._subscriptionsUrl, account)
            .pipe(
              tap((response: ISubscriptionAccount) => {

                this.logger.trace('Updated Subscription account', deepCopy(response));
                const existingAccountIndex = this._subscriptionAccounts.findIndex(a => a.id == response.id);
                if (-1 < existingAccountIndex) {
                  //   this._subscriptionAccounts.push(response);
                  // } else {
                  this._subscriptionAccounts[existingAccountIndex] = response;
                }
                if (makeCurrent && this.subscriptionAccount().id !== response.id) {

                  this.currentSubscriptionAccount = response;
                }
              })
            )
        })
      );
  }


  /**
   * @param subscriptionInvitationLink
   * @returns 
   */
  updateSubscriptionInvitationLink(subscriptionInvitationLink: ISubscriberInvitationLink): Observable<ISubscriberInvitationLink> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          return this.dataService.put<ISubscriberInvitationLink>(this._subscriberInvitationsUrl, subscriptionInvitationLink)
            .pipe(
              tap((response: ISubscriberInvitationLink) => this.logger.trace('Updated Subscription invitation', deepCopy(response)))
            )
        })
      );
  }



  /**
   * Does not send or receive Venue child entities
   * @param subscriptionUser
   * @returns 
   */
  updateSubscriptionUser(subscriptionUser: ISubscriptionUser): Observable<ISubscriptionUser> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._subscriptionsUrl}/subscribers`;

          return this.dataService.put<ISubscriptionUser>(url, subscriptionUser)
            .pipe(
              tap((response: ISubscriptionUser) => {

                this.logger.trace('Updated Subscription user', deepCopy(response));
                if (response.id === this.subscriptionUser().id) {

                  this.subscriptionUserSignal.set(new SubscriptionUser());
                  this.validateSubscriptionUser();
                }
              })
            )
        })
      );
  }


  /**
   * Does not send or receive Venue child entities
   * @param venue
   * @returns 
   */
  updateVenue(venue: IVenue): Observable<IVenue> {

    return this.whenReady$
      .pipe(switchMap(x => {

        return this.dataService.put<IVenue>(this._venuesUrl, venue)
          .pipe(
            tap((response: IVenue) => this.logger.trace('Updated Venue', deepCopy(response)))
          )
      }));
  }


  private _showroomUser = new ShowroomUser();
  private async validateSubscriptionAccount(showroomUser: ShowroomUser) {

    if (showroomUser.id === this._showroomUser.id) {

      this.logger.trace('Ignored duplicate Showroom User', showroomUser);
      return;
    }
    this._showroomUser = showroomUser;

    const subscriptionAccount = await firstValueFrom(this.currentSubscriptionAccount$);

    if (subscriptionAccount) {

      await this.validateSubscriptionUser();
      await this.validateShowroomAccount();
    }
  }


  private async validateShowroomAccount() {

    await firstValueFrom(this.currentShowroomAccount$);
  }


  private async validateSubscriptionUser() {

    await firstValueFrom(this.currentSubscriptionAccountUser$);
  }


  private _OnDestroy(): void {

    this._rxjsSubscriptions.forEach(s => s.unsubscribe());
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }


}
