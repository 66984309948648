import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from "../../../shared/components/top/navbar/navbar.component";
import { CartSidebarComponent } from "../../../components/cart/cart-sidebar/cart-sidebar.component";
import { FooterComponent } from "../../../shared/components/bottom/footer/footer.component";
import { AltFooterComponent } from "../../../shared/components/bottom/alt-footer/alt-footer.component";

@Component({
    selector: 'app-privacy',
    standalone: true,
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.scss'],
    imports: [CommonModule, NavbarComponent, CartSidebarComponent, FooterComponent, AltFooterComponent]
})
export class PrivacyComponent {

}
