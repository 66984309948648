import { DestroyRef, Injectable, inject, signal } from '@angular/core';
import { ConfigurationService } from '../configuration/configuration.service';
import { DataService } from '../data/data.service';
import { IShowroomUser, ShowroomUser } from 'projects/my-common/src/model';
import { Observable } from 'rxjs/internal/Observable';
import { map, switchMap } from 'rxjs/operators';
import { SecurityService } from '../authentication/security.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { Subject } from 'rxjs/internal/Subject';
import { NGXLogger } from 'ngx-logger';
import { Subscriber, firstValueFrom } from 'rxjs';
import { URL_BASE } from 'src/environments/environment';
import { SHOWROOM_ENDPOINT } from 'src/environments/interfaces/IEnvironment';


/**
 * Depends/builds upon SecurityService
 * Supports Administrative inquiries 
 * Is authenticated user a MyOptyx User 
 * If not then create MyOptyx User 
 * Is MyOptx User an Admin 
 * https://www.youtube.com/watch?v=EJey9KP1dZA
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly _destroying$ = new Subject<void>();
  private _destroyRef = inject(DestroyRef);
  private _showroomUsersUrl = '';
  private _subscriptions: Subscription[] = [];

  private readonly showroomUserSignal = signal(new ShowroomUser());
  readonly showroomUser = this.showroomUserSignal.asReadonly();

  /**
   * Get current User.
   * If current User undefined then will request from server and return final result
   */
  private _gettingShowroomUser = false;                          // Concurrency flag
  private _showroomUserQueue: Subscriber<ShowroomUser | undefined>[] = [];   // Concurrency queue
  private _getCurrentUserError: any;    // validateMyOptyxUser() handles this error
  readonly showroomUser$ = new Observable<ShowroomUser | undefined>((observer) => {

    if (this.showroomUser().isValid) {

      // We already have currentUser
      observer.next(this.showroomUser());
      return;
    }
    // Dependency check
    if (!this.securityService.isAuthenticated) {

      this.logger.trace('User not authenticated');
      observer.next(undefined);
      return;
    }
    this._showroomUserQueue.push(observer);
    if (this._gettingShowroomUser) {

      return;
    }
    this._gettingShowroomUser = true;

    let subscription: Subscription | undefined;
    const unsubscribe = () => subscription?.unsubscribe();
    subscription = this.getMe().subscribe({
      next: (user) => {

        this.showroomUserSignal.set(user ? new ShowroomUser(user) : new ShowroomUser());
        this._showroomUserQueue.forEach(o => o.next(this.showroomUser()));
        this._showroomUserQueue = [];
        this._gettingShowroomUser = false;

        unsubscribe();
      },
      error: (error) => {
        this.showroomUserSignal.set(new ShowroomUser());
        this._getCurrentUserError = error;
        this._showroomUserQueue.forEach(o => o.next(undefined));
        this._showroomUserQueue = [];
        this._gettingShowroomUser = false;

        unsubscribe();
      }
    })
  });

  private _isReady: boolean = false;
  get isReady(): boolean {
    return this._isReady;
  }
  // Observable that is fired when service is ready
  private readonly _serviceReadySource = new Subject<any>();
  private readonly serviceReady$ = this._serviceReadySource.asObservable();
  private _gettingReady = false;                        // Concurrency flag
  private _whenReadyQueue: Subscriber<unknown>[] = [];  // Concurrency queue
  readonly whenReady$ = new Observable((observer) => {

    if (this._isReady) {

      observer.next();
      return;
    }
    this._whenReadyQueue.push(observer);
    if (this._gettingReady) {

      return;
    }
    this._gettingReady = true;

    let subscription: Subscription | undefined;
    const unsubscribe = () => subscription?.unsubscribe();
    subscription = this.serviceReady$.subscribe(() => {
        // setUrls() is called in constructor we're waiting for it to finish
        this._whenReadyQueue.forEach(o => o.next());
        this._whenReadyQueue = [];
        this._gettingReady = false;

        unsubscribe();
      });
  });


  constructor(private readonly configurationService: ConfigurationService,
    private readonly dataService: DataService,
    private readonly securityService: SecurityService,
    private readonly logger: NGXLogger
  ) {

    this.setUrls();
    this._destroyRef.onDestroy(() => this._OnDestroy());

    this.monitorAuthenticationChanges();
  }


  createUser(): Observable<ShowroomUser> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          return this.dataService.post<IShowroomUser>(this._showroomUsersUrl, null)
            .pipe(
              map((response: IShowroomUser) => new ShowroomUser(response))
            )
        })
      );
  }


  /**
   * Get MyOptyx User for the current, authenticated identity
   * Without Accounts and SubscribedAddcounts
   * @returns IUser interface
   */
  getMe(): Observable<ShowroomUser> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._showroomUsersUrl}/me`;

          return this.dataService.get<IShowroomUser>(url)
            .pipe(
              map((user) => {

                this.logger.trace('Me', user);
                return new ShowroomUser(user);
              })
            )
        })
      );
  }


  getShowroomUserByEmails(emails: string): Observable<ShowroomUser> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._showroomUsersUrl}/email/${emails}`;

          return this.dataService.get<IShowroomUser>(url)
            .pipe(
              map((response: IShowroomUser) => {

                this.logger.trace('Showroom user', response);
                return new ShowroomUser(response);
              })
            )
        })
      );
  }


  getShowroomUserById(userId: number): Observable<ShowroomUser> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._showroomUsersUrl}/${userId}`;

          return this.dataService.get<IShowroomUser>(url)
            .pipe(
              map((response: IShowroomUser) => {

                this.logger.trace('Showroom user', response);
                return new ShowroomUser(response);
              })
            )
        })
      );
  }


  private monitorAuthenticationChanges() {

    this.validateMyOpyxUser();
    this._subscriptions.push(
      this.securityService.isAuthenticatedUpdate$
        .subscribe((isAuthorized) => {

          this._getCurrentUserError = undefined;
          this.showroomUserSignal.set(new ShowroomUser());
          this.validateMyOpyxUser()
        })
    );
  }


  private _OnDestroy(): void {

    this._subscriptions.forEach(s => s.unsubscribe());
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }


  private async setUrls() {

    // This is the only method that depends on _configurationService.whenReady
    if (!this.configurationService.isReady) {

      await firstValueFrom(this.configurationService.whenReady$);
    }

    this._showroomUsersUrl = `${URL_BASE.SHOWROOM}/${SHOWROOM_ENDPOINT.User}`;

    this._isReady = true;

    this.validateMyOpyxUser();
    this._serviceReadySource.next(true);
  }


  updateUser(user: ShowroomUser): Observable<ShowroomUser> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          return this.dataService.put<IShowroomUser>(this._showroomUsersUrl, user)
            .pipe(
              map((response: IShowroomUser) => {

                this.logger.trace('Updated user', response);
                return new ShowroomUser(response);
              })
            )
        })
      );
  }


  /**
   * When login occurs get the MyOptyx User.
   * If MyOptyx User not found for login then create MyOptyx User for login.
   */
  private async validateMyOpyxUser(): Promise<void> {

    let user = await firstValueFrom(this.showroomUser$);
    if (1 > this.showroomUser().id
      && this._getCurrentUserError
      && this._getCurrentUserError.status
      && 404 === this._getCurrentUserError.status) {

      // If existing MyOptyx User not found then create one
      user = await firstValueFrom(this.createUser());
      this.logger.trace('Created user', user);
      this.showroomUserSignal.set(new ShowroomUser(user));
    }
  }


}
