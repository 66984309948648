import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from "./shared/components/top/header/header.component";
import { FooterComponent } from './shared/components/bottom/footer/footer.component';
import { CartSidebarComponent } from "./components/cart/cart-sidebar/cart-sidebar.component";
import { AltFooterComponent } from "./shared/components/bottom/alt-footer/alt-footer.component";
import { ToastComponent } from "./shared/components/tools/toast/toast.component";
import { environment } from 'src/environments/environment';
import { SwUpdate } from '@angular/service-worker';
import { Configuration } from 'src/environments/interfaces/IEnvironment';
import { NGXLogger } from 'ngx-logger';
import { AbilityModule } from '@casl/angular';

declare const gtag: Function;   // Google analytics


@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    FooterComponent,
    NgIf,
    RouterOutlet,
    HeaderComponent,
    CartSidebarComponent,
    AltFooterComponent,
    ToastComponent,
    AbilityModule
  ]
})
export class AppComponent {
  //title: string = 'me';

  constructor(private readonly router: Router,
    private readonly logger: NGXLogger,
    private readonly updates: SwUpdate) {

    environment.logger = logger;

    this.monitorRouterEvents();

    if (environment.configuration === Configuration.PRODUCTION) {
      gtag('js', new Date());
      gtag('config', 'G-HVZMVYPD4C');
    }

    // Service worker registration is setup in config.app.ts
    // this.registerServiceWorker();

    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    // const appIsStable$ = appRef.isStable.pipe(
    //   tap(isStable => console.log(`--> isStable: ${isStable}`)),
    //   first(isStable => isStable === true)
    //   );
    //const everySixHours$ = interval(6 * 60 * 60 * 1000);
    // const everySixHours$ = interval(60 * 1000);
    //const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
    // const everySixHoursOnceAppIsStable$ = concat(everySixHours$);

    // everySixHoursOnceAppIsStable$.subscribe(async () => {
    //   try {
    //     const updateFound = await _updates.checkForUpdate();
    //     console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
    //   } catch (err) {
    //     console.error('Failed to check for updates:', err);
    //   }
    // });

    if (environment.configuration !== Configuration.DEVELOP) {

      this.monitorApplicationUpdates();
    }
  }


  private async registerServiceWorker() {
    // Added service worker here to make it load in Edge.
    // The provider registration was enough for Chrome but not Edge
    if ("serviceWorker" in navigator) {// && environment.configuration !== Configuration.DEVELOP) {
      this.logger.trace('--> Registering service worker ...')
      try {
        const registration = await navigator.serviceWorker.register("/ngsw-worker.js", {
          scope: "/",
        });
        if (registration.installing) {
          console.log("Service worker installing");
        } else if (registration.waiting) {
          console.log("Service worker installed");
        } else if (registration.active) {
          console.log("Service worker active");
          //this.monitorApplicationUpdates();
        }
      } catch (error) {
        console.error(`Registration failed with ${error}`);
      }
    }

  }


  private async monitorApplicationUpdates() {

    const updateAvailable = await this.updates.checkForUpdate();
    if (updateAvailable) {

      console.warn('Application update is available.');
      this.updates.activateUpdate().then(() => document.location.reload());
    }    

    this.updates.versionUpdates.subscribe(async event => {

      switch (event.type) {

        case 'VERSION_DETECTED':
          console.warn(`Downloading new app version: ${event.version.hash}`);
          break;
        
        case 'VERSION_READY':
          console.warn(`Current app version: ${event.currentVersion.hash}`);
          console.warn(`New app version ready for use: ${event.latestVersion.hash}`);
          if (confirm('A new version of Showroom is available. Reload?')) {

            this.updates.activateUpdate().then(() => document.location.reload());
          }
          break;
        
        case 'VERSION_INSTALLATION_FAILED':
          console.warn(`${event.version.hash}: ${event.error}`);
          break;
      }
    });
  }


  monitorRouterEvents() {
    if (environment.configuration === Configuration.PRODUCTION) {
      // Report page navigations to Google analytics
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          gtag('config', 'G-HVZMVYPD4C', { 'page_path': event.urlAfterRedirects });
        }
      })
    }
  }


}
