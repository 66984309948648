import { IVenue } from "projects/my-common/src/model";
import { IPurchaseOrder } from "src/app/core/model/purchase.model";
import { ISubscriptionUser } from "./IUser";

// Must stay in sync with server
export enum AccountState {
    Inactive = 0,
    Active = 1,
    ReadOnly = 2
}


export function accountStateToString(state: AccountState): string {
    switch (state) {
        case AccountState.Inactive: return 'Inactive';
        case AccountState.Active: return 'Active';
        case AccountState.ReadOnly: return 'Read only';
        default: throw Error(`--> Invalid AccountState: ${state}`);
    }
}


/**
 * The Subscription User Summary holds basic User data from the Account Db.
 */
export interface ISusbscriptionUserSummary {

    id: number
    roleMask: number
    userName: string
    emails: string
    accountCount: number
}


export type IShowroomAccount = {

    id: number
    accountNumber: string
    state: AccountState
    accountOwnerId: number
    expires: Date
    venues: IVenue[]
}


export type IShowroomAccountStat = {

    accountNumber: string
    venueId: number
    imagePropCount: number
    objectPropCount: number
    imageGigabyteCount: number
    objectGigabyteCount: number
}


/**
 * For posting new account creation requests
 */
export type INewSubscriptionAccount = {

    id: number
    accountNumber: string
    state: AccountState
    accountOwnerEmails: string
    description: string
    expires: Date
}



export type ISubscriptionAccount = {
    id: number
    accountNumber: string
    state: AccountState
    accountOwnerId: number
    description: string
    expires: Date
    pricePlan: IPricePlan
    subscribers: ISubscriptionUser[]
    subscriberInvitationLinks: ISubscriberInvitationLink[]
    invoices: IInvoice[]
    purchaseOrders: IPurchaseOrder[]
}


export type IPricePlan = {
    id: number;
    name: string;
    description: string;
    venueMonthlyPrice: number;
    imagePropMonthlyPrice: number;
    objectPropMonthlyPrice: number;
    perGigabyteMonthlyPrice: number;
    complimentarySubscriberCount: number;
    subscriberMonthlyPrice: number;
}


export interface ISubscriberInvitationLink {
    id: number;
    accountId: number;
    emails: string;
    notes: string;
    invitationGuid: string;
    expires: Date;
    redeemed: boolean;
    redeemedOn: Date;
    replaceAccountOwner: boolean;
}


export interface IInvoiceItem {
    id: number;
    productGroupName: string;
    productGroupId: number;
    productName: string;
    unitPrice: number;
    discount: number;
    units: number;
}


export enum InvoiceStatus {
    Submitted = 1,
    AwaitingValidation = 2,
    Unpaid = 3,
    Paid = 4,
    Cancelled = 5
}


export interface IInvoice {
    id: number;
    accountNumber: string;
    billedOn: Date;
    buyerId: number;
    clientSecret: string;
    description: string;
    dueOn: Date;
    invoiceStatusId: InvoiceStatus;
    paidAmount: number;
    paidOn: Date;
    periodEnd: Date;
    periodStart: Date;
    redirectUrl: string;
    stripeMode: string;
    stripeInvoiceNumber: string;
    stripeInvoicePdf: string;

    invoiceItems: IInvoiceItem[];
}