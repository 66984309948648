import { DestroyRef, Injectable, effect, inject, signal } from '@angular/core';
import { DataService } from '../data/data.service';
import { ICart, ICartCheckout, ICartItem, IShowroomCart, IShowroomCartItem } from 'src/app/core/model/cart.model';
import { ConfigurationService } from '../configuration/configuration.service';
import { Observable, Subject, Subscriber, Subscription, firstValueFrom } from 'rxjs';
import { ICatalogItem } from 'src/app/core/model/catalog.model';
import { NGXLogger } from 'ngx-logger';
import { GuestService } from '../venue/guest.service';
import { AccountService } from '../myoptyx/account.service';
import { deepCopy } from 'projects/mp-core/src/lib/util';
import { URL_BASE } from 'src/environments/environment';
import { GuestUser, ShowroomAccount } from 'projects/my-common/src/model';

const CART_SESSION_ID = 'cartSessionID';

/**
 * Cart API is bound by server generated SessionId. Carts are also associated with an Account.
 * Carts primary goal is to streamline the generation of Purchase orders related to Ad space and signage purchase and installation.
 * Guest users are the target user with accounts derived from the Venue Event associated with the Guest link guid.
 * Otherwise, if the Account changes (unlikely with Guest users) then the Cart is cleared. 
 */
@Injectable({
  providedIn: 'root'
})
export class CartService {

  private readonly _destroying$ = new Subject<void>();
  private _destroyRef = inject(DestroyRef);
  private _subscriptions: Subscription[] = [];

  private _cartUrl: string = '';
  cart: ICart = {
    sessionId: '',
    items: []
  };

  readonly showroomCart = signal(<IShowroomCart>{
    accountNumber: '',
    items: [],
    sessionId: '',
  });

  // Observable fired when Cart mutates
  private _cartUpdateSource = new Subject<ICart>();
  cartUpdate$ = this._cartUpdateSource.asObservable();

  private _showroomCartUpdateSource = new Subject<IShowroomCart>();
  showroomCartUpdate$ = this._showroomCartUpdateSource.asObservable();

  private _isReady: boolean = false;
  get isReady(): boolean {
    return this._isReady;
  }
  // observable that is fired when urls are set
  private readonly _urlsSetSource = new Subject<any>();
  private readonly urlsSet$ = this._urlsSetSource.asObservable();
  private _gettingReady = false;
  private _whenReadyQueue: Subscriber<unknown>[] = [];
  readonly whenReady$ = new Observable((observer) => {
    if (this._isReady) {
      observer.next();
      return;
    }

    this._whenReadyQueue.push(observer);
    if (!this._gettingReady) {
      this._gettingReady = true;
      this.urlsSet$.subscribe(() => {
        this._whenReadyQueue.forEach(o => o.next());
        this._whenReadyQueue = [];
        this._gettingReady = false;
      });
    }
  });


  constructor(private readonly accountService: AccountService,
    private readonly configurationService: ConfigurationService,
    private readonly dataService: DataService,
    private readonly guestService: GuestService,
    private readonly logger: NGXLogger) {

    this._destroyRef.onDestroy(() => this.onDestroy());
    this.setUrls();

    effect(() => this.validateCartAccount(guestService.guestUser(), new ShowroomAccount()),
      { allowSignalWrites: true });
    // Delay cart validation connected with Showroom Account to give preference to Guest User cart validation.
    effect(() => setTimeout(() => {

      this.validateCartAccount(new GuestUser(), this.accountService.showroomAccount());
    }, 100),
      { allowSignalWrites: true });
  }


  async addCatalogItemToCart(item: ICatalogItem): Promise<void> {

    let cartItem = this.cart.items.find(value => value.productId == item.id);
    if (cartItem) {

      cartItem.quantity++;
    } else {

      let newCartItem: ICartItem = {
        pictureUrl: item.pictureUri,
        productId: item.id,
        productName: item.name,
        quantity: 1,
        unitPrice: item.price,
        id: crypto.randomUUID(),
        oldUnitPrice: 0
      };

      this.cart.items.push(newCartItem);
    }

    await this.postCartAndBroadcast();
  }


  async addShowroomCartItem(item: IShowroomCartItem): Promise<void> {

    this.showroomCart.update(sc => {

      sc.accountNumber = sc.accountNumber ? sc.accountNumber : '';
      sc.items.push(item);
      return { ...sc };
    });

    this.logger.trace('Cart', this.showroomCart());
    await this.postShowroomCartAndBroadcast();
  }


  async changeItemQuantity(cartItemIndex: number, newQuantity: number): Promise<void> {

    if (1 > newQuantity) {

      throw new Error(`Invalid cart item quantity: ${newQuantity}`);
    }
    if (this.cart.items.length <= cartItemIndex || 0 > cartItemIndex) {

      throw new Error(`Invalid cart item index: ${cartItemIndex}`);
    }
    if (this.cart.items[cartItemIndex].quantity == newQuantity) {

      return;
    }

    this.cart.items[cartItemIndex].quantity = newQuantity
    await this.postCartAndBroadcast();
  }


  async clearShowroomCart(): Promise<void> {

    if (1 > this.showroomCart().items.length) {

      return;
    }

    this.showroomCart.update(sc => {

      sc.items = [];
      return { ...sc };
    });
    await this.postShowroomCartAndBroadcast();
  }


  async decreaseItemQuantity(cartItemIndex: number): Promise<void> {

    if (this.cart.items.length <= cartItemIndex || 0 > cartItemIndex) {

      throw new Error(`Invalid cart item index: ${cartItemIndex}`);
    }
    if (2 > this.cart.items[cartItemIndex].quantity) {

      return;
    }

    this.cart.items[cartItemIndex].quantity--;
    await this.postCartAndBroadcast();
  }


  /**
   * Pull cart from server
   * @returns 
   */
  async getCart(): Promise<void> {

    await firstValueFrom(this.whenReady$);

    const cartSessionId = window.localStorage.getItem(CART_SESSION_ID);
    let url: string = `${this._cartUrl}/${cartSessionId ?? 'new'}`;

    const response = await firstValueFrom(this.dataService.get<ICart>(url));
    this.cart = response;
    window.localStorage.setItem(CART_SESSION_ID, this.cart.sessionId);
    this._cartUpdateSource.next(this.cart);
  }


  async getShowroomCart(): Promise<void> {

    await firstValueFrom(this.whenReady$);

    const cartSessionId = window.localStorage.getItem(CART_SESSION_ID);
    let url: string = `${this._cartUrl}/showroom/${cartSessionId ?? 'new'}`;

    const showroomCart = await firstValueFrom(this.dataService.get<IShowroomCart>(url));
    if (showroomCart) {

      this.logger.trace('Showroom cart', showroomCart);
      this.showroomCart.set(showroomCart);
      window.localStorage.setItem(CART_SESSION_ID, this.showroomCart().sessionId);
      this._showroomCartUpdateSource.next(this.showroomCart());
    }
  }


  async increaseItemQuantity(cartItemIndex: number): Promise<void> {

    if (this.cart.items.length <= cartItemIndex || 0 > cartItemIndex) {

      throw new Error(`Invalid cart item index: ${cartItemIndex}`);
    }

    this.cart.items[cartItemIndex].quantity++;
    await this.postCartAndBroadcast();
  }


  private onDestroy(): void {

    this._subscriptions.forEach(s => s.unsubscribe());
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }


  private async postCartAndBroadcast(): Promise<void> {

    await firstValueFrom(this.whenReady$);

    const response = await firstValueFrom(this.dataService.post<ICart>(this._cartUrl, this.cart));

    this.cart = response;
    this._cartUpdateSource.next(this.cart);
  }


  private async postShowroomCartAndBroadcast(): Promise<void> {

    await firstValueFrom(this.whenReady$);

    const url = `${this._cartUrl}/showroom`;
    const response = await firstValueFrom(this.dataService.post<IShowroomCart>(url, this.showroomCart()));

    this.showroomCart.set(response);
    this._showroomCartUpdateSource.next(this.showroomCart());
  }


  async removeItem(cartItemIndex: number): Promise<void> {

    if (this.showroomCart().items.length <= cartItemIndex || 0 > cartItemIndex) {

      throw new Error(`Invalid cart item index: ${cartItemIndex}`);
    }

    this.showroomCart.update(sc => {

      sc.items.splice(cartItemIndex, 1);
      return { ...sc };
    })
    await this.postShowroomCartAndBroadcast();
  }


  async setCartCheckout(cartCheckout: ICartCheckout): Promise<string> {

    await firstValueFrom(this.whenReady$);

    let url = `${this._cartUrl}/checkout`;

    return await firstValueFrom(this.dataService.post(url, cartCheckout));
  }


  /**
   * Changing the Account clears the Cart. Not typical for a Guest User.
   * @param accountNumber 
   */
  private async setShowroomCartAccount(accountNumber: string): Promise<void> {

    if (this.showroomCart().accountNumber === accountNumber) {

      return;
    }

    this.showroomCart.update(sc => {

      // If changing account numbers the clear cart
      if (sc.accountNumber && 0 < sc.accountNumber.length) {

        sc.items = [];
      }
      sc.accountNumber = accountNumber;
      return { ...sc };
    })
    this.logger.trace('Cart', this.showroomCart());

    await this.postShowroomCartAndBroadcast();
  }


  private async setUrls(): Promise<void> {

    if (!this.configurationService.isReady) {

      await firstValueFrom(this.configurationService.whenReady$);
    }

    this._cartUrl = `${URL_BASE.CART}/cart`;
    this._isReady = true;
    this._urlsSetSource.next(true);

    await this.getShowroomCart();
  }


  /**
   * A GuestUser is instantiated when the GuestService has a valid invitation Guid and 
   * a user logs in.
   * If Guest User is defined then the Account Number they are acting under is applied to the Cart.
   * Otherwise, apply the current Showroom Account Number to the Cart if it is defined.
   */
  private async validateCartAccount(guestUser: GuestUser, showroomAccount: ShowroomAccount): Promise<void> {

    if (guestUser.accountNumber !== this.guestService.guestUser().accountNumber) {

      // Call was made by Showroom Account effect but we are prioritizing Guest User effects.
      return;
    }


    let accountNumber: string | undefined;
    if (0 < guestUser.accountNumber.length) {

      accountNumber = guestUser.accountNumber;
      this.logger.trace(`Setting Cart account from Guest User: ${accountNumber}`, guestUser, deepCopy(this.showroomCart()));
    } else if (0 < showroomAccount.accountNumber.length) {

      accountNumber = showroomAccount.accountNumber;
      this.logger.trace(`Setting Cart account from Showroom Account: ${accountNumber}`, showroomAccount);
    }

    if (accountNumber) {

      await this.setShowroomCartAccount(accountNumber);
    }
  }

}
