import { AfterViewInit, Component, Input, OnDestroy, QueryList, ViewChildren, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecurityService } from 'src/app/core/service/authentication/security.service';
import { Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { NGXLogger } from 'ngx-logger';
import { AccountService } from 'src/app/core/service/myoptyx/account.service';
import { RoutePrefix } from 'src/app/config.routes';
import { UserService } from 'src/app/core/service/myoptyx/user.service';

@Component({
    selector: 'app-account-menu',
    standalone: true,
    imports: [CommonModule, RouterLink],
    templateUrl: './account-menu.component.html',
    styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent implements AfterViewInit, OnDestroy {
    private _subscriptions: Subscription[] = [];

    readonly visible = signal(false);
    @Input() set Visible(value: boolean) {
        this.visible.set(value);
    }

    readonly OnCloseMenu = output<object>();


    constructor(private readonly _router: Router,
        private readonly _securityService: SecurityService,
        readonly account: AccountService,
        private readonly user: UserService,
        private readonly _logger: NGXLogger) { }


    handleBlur(blurEvent: any) {

        if (blurEvent.relatedTarget) {
            
            return;
        }
        this.visible.set(false);
        this.OnCloseMenu.emit({});
    }


    editProfile() {
        
        //this._securityService.editProfile();
        this._logger.warn('Implement MyOptyx User or Account profile instead of ADB2C.');
    }


    logout() {
        this._securityService.logout();
    }


    /**
     * Setup for close on blur when user clicks away from menu
     */
    @ViewChildren('accountMenu') accountMenu!: QueryList<HTMLInputElement>;
    ngAfterViewInit() {

        this._subscriptions.push(
            this.accountMenu.changes.subscribe((accountMenus) => {
                if (0 < accountMenus.length) {

                    // Set focus on the opened menu
                    accountMenus.first.nativeElement.focus();
                    this._logger.trace('accountMenu focus');
                }
            })
        );
    }


    ngOnDestroy(): void {
        this._subscriptions.forEach(s => s.unsubscribe());
    }


    async onViewAccount(accountNumber: string) {

        this.visible.set(false);
        this.OnCloseMenu.emit({});

        this._logger.trace(`Setting active account ${accountNumber}`);
        await this.account.setActiveAccount(accountNumber);

        if (this.user.showroomUser().isAdmin) {

            this._router.navigate([`/${RoutePrefix.STAGE}/account/${accountNumber}`]);
        } else {
            
            this._router.navigate([`/${RoutePrefix.CLIENT}/account/${accountNumber}`]);
        }
    }


}
