import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartSidebarComponent } from "../../../components/cart/cart-sidebar/cart-sidebar.component";
import { FooterComponent } from "../../../shared/components/bottom/footer/footer.component";
import { AltFooterComponent } from "../../../shared/components/bottom/alt-footer/alt-footer.component";
import { NavbarComponent } from "../../../shared/components/top/navbar/navbar.component";
import { AccountService } from 'src/app/core/service/myoptyx/account.service';
import { GuestService } from 'src/app/core/service/venue/guest.service';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-tos',
    standalone: true,
    templateUrl: './tos.component.html',
    styleUrls: ['./tos.component.scss'],
    imports: [CommonModule, CartSidebarComponent, FooterComponent, AltFooterComponent, NavbarComponent]
})
export class TosComponent implements OnDestroy {

    readonly guid = crypto.randomUUID();
    private _stopPolling = false;
    private _timeout?: NodeJS.Timeout;


    constructor(private readonly _accountService: AccountService,
        private readonly _guestService: GuestService) {

        this.pollServices();
    }
    
    
    ngOnDestroy(): void {
    
        this._stopPolling = true;
        if (this._timeout) {

            clearTimeout(this._timeout);
        }
    }


    private async pollServices(): Promise<void> {

        if (this._stopPolling) {

            return;
        }

        // Poll Showroom service
        await firstValueFrom(this._guestService.setInvitationGuid(this.guid));
        // Poll Account service
        await firstValueFrom(this._accountService.getSubscriptionMe());

        this._timeout = setTimeout(() => this.pollServices(), 300000);
    }

}
