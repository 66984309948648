<div #cartSidebarComponent tabindex="0" [autofocus]="setFocus()" (blur)="onBlur()"
    class="fixed h-screen overflow-hidden top-0 bottom-0 w-full z-50 md:max-w-[500px] bg-[#0e0f10] shadow-xl 
            {{ isOpen() ? 'right-0' : '-right-full' }} transition-all duration-300 px-4 bg-opacity-80 border-l-[1px] border-accent">
    <div class="flex flex-row justify-between items-center">
        <div class="flex flex-row">
            <!-- close icon -->
            <div (click)="onClose()" 
                class=" text-4xl w-11 h-[70px] flex justify-start items-center cursor-pointer transition">
                <ng-icon class="text-3xl transition {{ closing() ? 'rotate-[2turn]' : 'rotate-0' }}"
                    name="bootstrapXLg" />
            </div>
            <div class="flex items-center text-accent text-2xl font-semibold overflow-hidden">
                {{cartService.showroomCart().items.length}} &nbsp; Item{{1 < cartService.showroomCart().items.length ? 's' : '' }}
            </div>
        </div>
    </div>
    <div class="overflow-y-auto overflow-x-hidden h-full pb-[275px]">
        <div class="flex flex-col gap-y-10 pb-2 border-b">
                
            @for (item of cartService.showroomCart().items; track $index) {     

                <app-showroom-cart-item [product]="item" [index]="$index" (onRemoveItem)="removeItem($event)"
                    (onIncreaseQuantity)="increaseQuantity($event)" (onDecreaseQuantity)="decreaseQuantity($event)"
                    (onChangeQuantity)="changeQuantity($event)" />
                
            }

        </div>
    </div>


    @if (1 > cartService.showroomCart().items.length) {

        <div class="h-full absolute top-0 right-0 left-0 flex justify-center items-center -z-10 flex-col text-white/30">
            <div class="text-2xl">Cart is empty</div>
            <div><ng-icon class="text-6xl" name="bootstrapCart3" /></div>
        </div>
        
    }

    <!-- totals and checkout -->
    <div class="sticky bottom-0 bg-black rounded-md py-5">

        @if (0 < cartService.showroomCart().items.length) { 
                
            <div class="px-6 pb-10 flex flex-1 flex-col">
                <!-- subtotal -->
                <div class="flex justify-between text-lg">
                    <div>Subtotal</div>
                    <div>$ {{ totalPrice() | number: '1.2-2' }}</div>
                </div>
                <!-- total -->
                <div class="flex justify-between text-2xl">
                    <div>Total</div>
                    <div>$ {{ totalPrice() | number: '1.2-2' }}</div>
                </div>
            </div>
        }

        <!-- buttons -->
        <div class="px-6">
            
            @if (0 < cartService.showroomCart().items.length) { 
                    
                <div class="flex justify-between gap-x-4">
                    <button class="btn btn-accent hover:bg-accent-hover text-primary" (click)="clearCart()">
                        Clear cart
                    </button>
                    <button (click)="onCheckout()" 
                        class="btn btn-accent hover:bg-accent-hover text-primary flex-1 px-2 gap-x-2">
                        Purchase order
                        <ng-icon class="text-lg" name="bootstrapChevronRight" />
                    </button>
                </div>
        
            }
        
            <div class="text-sm py-0 text-right text-gray-400">Login required</div>
        </div>
    </div>
</div>