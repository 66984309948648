import { ICaslPermission } from '../../auth/interface/IPermission';
import { ISubscriptionAccount } from './IAccount'

export enum RequiredInformationMissing {
    None = 0,
    Address = 1,
    BillingInfo = 2,
    BillingInfoAndAddress = 3
}


export interface IAddress {

    line1: string
    line2: string
    city: string
    state: string
    postalCode: string
    country: string
}


/**
 * A GuestUser is instantiated by the GuestService when a User with a valid invitation Guid logs in.
 * The MyOptyx GuestUser is managed in the Showroom Db.
 * User information is expanded upon there.
 */
export interface IGuestUser extends IShowroomUser {
    /**
     * Under which Account authority is this GuestUser operating
     */
    accountNumber: string
}


/**
 * The initial MyOptyx User record is created in the Showroom DB and sync'ed with Purchase DB via Integration Events.
 * It only holds basic User data.
 * Showroom Db is the authoritative source for this inital, basic User data.
 */
export interface IShowroomUser {

    id: number
    roleMask: number
    userName: string
    emails: string
    permissions: ICaslPermission[]
}


/**
 * The MyOptyx SubscriptionUser is managed in the Purchase Db
 * It extends User data to include Accounts, Account Status', Billing Information and Invoices.
 */
export interface ISubscriptionUser extends IShowroomUser {

    companyName: string
    firstName: string
    lastName: string
    address: IAddress
    accounts: ISubscriptionAccount[]
    subscribedAccounts: ISubscriptionAccount[]
    requiredInformationMissing: RequiredInformationMissing
}