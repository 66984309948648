import { DestroyRef, Injectable, computed, effect, inject, signal } from '@angular/core';
import { ConfigurationService } from '../configuration/configuration.service';
import { DataService } from '../data/data.service';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject, Subscriber, Subscription, firstValueFrom, map, of, switchMap, tap } from 'rxjs';
import {
  DesignImage, DesignObject, DesignVideo, EventDesign, GuestUser, IDesignImage, IDesignObject, IDesignVideo,
  IGuestUser, ImageAssignment, ImageProp, IObjectAssignment, IPurchaseGuestLink, IVenue, ObjectAssignment, ObjectProp, ShowroomUser, Venue,
  VenueEvent, VideoAssignment, VideoProp
} from 'projects/my-common/src/model';
import { UserService } from '../myoptyx/user.service';
import { URL_BASE } from 'src/environments/environment';
import { IVenueService } from './interface/IVenueService';
import { PURCHASE_ENDPOINT, SHOWROOM_ENDPOINT } from 'src/environments/interfaces/IEnvironment';
import { deepCopy } from 'projects/mp-core/src/lib/util';


export interface GuestState {

  currentDesignImageId: number
  currentDesignObjectId: number
  currentDesignVideoId: number
  currentEventDesignId: number
  currentImageAssignmentId: number
  currentObjectAssignmentId: number
  currentVideoAssignmentId: number
  currentImagePropId: number
  currentObjectPropId: number
  currentVideoPropId: number
  currentVenue: Venue
  currentVenueEventId: number
  errorMessage?: string
}

/**
 * Guest links include guids (expiring auth tokens) which grant access to a Showroom focused on a Venue Event without a login.
 * Access allows use of the Cart to create Purchase orders (PO) to streamline processes related to ad/print sales and installation.
 * Guests must login to generate a User Id allowing them to submit a PO.
 */
@Injectable({
  providedIn: 'root'
})
export class GuestService implements IVenueService {

  private readonly _destroying$ = new Subject<void>();
  private _destroyRef = inject(DestroyRef);
  private _purchseOrdersUrl = '';
  private _purchaseGuestLinkUrl = '';
  private _showroomGuestLinkUrl = '';
  /**
   * Guests that login are upgraded to GuestUsers
   * This url, which requires the User be authenticated, is used to pull the GuestUser info.
   */
  private _showroomUsersUrl = '';
  private _subscriptions: Subscription[] = [];
  private _currentInvitationGuid?: string;
  public get invitationGuid(): string | undefined {

    return this._currentInvitationGuid;
  }
  private set invitationGuid(value: string | undefined) {

    this._currentInvitationGuid = value;
    this.setPurchaseGuestLink();
  }

  private _state = signal<GuestState>({

    currentDesignImageId: 0,
    currentDesignObjectId: 0,
    currentDesignVideoId: 0,
    currentEventDesignId: 0,
    currentImageAssignmentId: 0,
    currentObjectAssignmentId: 0,
    currentVideoAssignmentId: 0,
    currentImagePropId: 0,
    currentObjectPropId: 0,
    currentVideoPropId: 0,
    currentVenue: new Venue(),
    currentVenueEventId: 0
  })

  // Redux Selectors
  readonly assignedImageProps = computed(() => this.imageProps()
    .filter(ip => this.currentEventDesign().imageAssignments.some(ia => ia.imagePropId === ip.id)));
  readonly assignedObjectProps = computed(() => this.objectProps()
    .filter(op => this.currentEventDesign().objectAssignments.some(oa => oa.objectPropId === op.id)));
  readonly assignedVideoProps = computed(() => this.videoProps()
    .filter(vp => this.currentEventDesign().videoAssignments.some(va => va.videoPropId === vp.id)));
  readonly currentDesignImage = computed(() => this.currentEventDesign().designImages
    .find(di => di.id === this._state().currentDesignImageId) ?? new DesignImage());
  readonly currentDesignObject = computed(() => this.currentEventDesign().designObjects
    .find(_do => _do.id === this._state().currentDesignObjectId) ?? new DesignObject());
  readonly currentDesignVideo = computed(() => this.currentEventDesign().designVideos
    .find(dv => dv.id === this._state().currentDesignVideoId) ?? new DesignVideo());
  readonly currentEventDesign = computed(() => this.currentVenueEvent()?.eventDesigns
    .find(ed => ed.id === this._state().currentEventDesignId) ?? new EventDesign());
  readonly currentImageAssignment = computed(() => this.currentEventDesign().imageAssignments
    .find(ia => ia.id === this._state().currentImageAssignmentId) ?? new ImageAssignment());
  readonly currentObjectAssignment = computed(() => this.currentEventDesign().objectAssignments
    .find(oa => oa.id === this._state().currentObjectAssignmentId) ?? new ObjectAssignment());
  readonly currentVideoAssignment = computed(() => this.currentEventDesign().videoAssignments
    .find(va => va.id === this._state().currentVideoAssignmentId) ?? new VideoAssignment());
  readonly currentImageProp = computed(() => this.venue().imageProps.
    find(ip => ip.id === this._state().currentImagePropId) ?? new ImageProp())
  readonly currentObjectProp = computed(() => this.venue().objectProps.
    find(op => op.id === this._state().currentObjectPropId) ?? new ObjectProp())
  readonly currentVideoProp = computed(() => this.venue().videoProps.
    find(vp => vp.id === this._state().currentVideoPropId) ?? new VideoProp())
  readonly currentVenueEvent = computed(() => this.venue().venueEvents
    .find(ve => ve.id === this._state().currentVenueEventId) ?? new VenueEvent());
  readonly defaultVenueEvent = computed(() => this.venue().venueEvents
    .find(ve => ve.isDefault) ?? new VenueEvent());
  readonly defaultEventDesign = computed(() => this.defaultVenueEvent().eventDesigns
    .find(ed => ed.isDefault) ?? new EventDesign());
  readonly designImages = computed(() => this.currentEventDesign().designImages);
  readonly eventDesigns = computed(() => this.currentVenueEvent().eventDesigns);
  readonly imageProps = computed(() => this.venue().imageProps);
  readonly objectAssignments = computed(() => this.currentEventDesign().objectAssignments);
  readonly objectPropCount = computed(() => this.objectProps().length);
  readonly objectProps = computed(() => this.venue().objectProps);
  readonly venue = computed(() => this._state().currentVenue);
  readonly videoProps = computed(() => this.venue().videoProps);
  readonly errorMessage = computed(() => this._state().errorMessage);

  isReady: boolean = false;
  // observable that is fired when urls are set
  private readonly _urlsSetSource = new Subject<any>();
  private readonly urlsSet$ = this._urlsSetSource.asObservable();
  private _gettingReady = false;
  private _whenReadyQueue: Subscriber<unknown>[] = [];
  readonly whenReady$ = new Observable((observer) => {
    if (this.isReady) {
      observer.next();
      return;
    }

    this._whenReadyQueue.push(observer);
    if (!this._gettingReady) {
      this._gettingReady = true;
      this.urlsSet$.subscribe(() => {
        this._whenReadyQueue.forEach(o => o.next());
        this._whenReadyQueue = [];
        this._gettingReady = false;
      });
    }
  })

  readonly guestUserSignal = signal(new GuestUser());
  readonly guestUser = this.guestUserSignal.asReadonly();

  /**
   * A Guest User is instantiated when the GuestService has a valid invitation GUID and 
   * a user logs in.
   * A Guest User is a plain User with an Account Number associated with the invitation GUID
   */
  public get currentGuestUser(): GuestUser {

    return this.guestUser();
  }
  private set currentGuestUser(v: GuestUser) {

    this.guestUserSignal.set(new GuestUser(v));
  }

  private _gettingGuestUser = false;     // concurrency flag
  private _guestUserQueue: Subscriber<GuestUser>[] = []; // concurrency queue
  readonly currentGuestUser$ = new Observable<GuestUser>((observer) => {

    if (this.guestUser().isValid) {

      observer.next(this.guestUser());
      return;
    }
    // Dependency check
    if (!this.userService.showroomUser().isValid || !this.invitationGuid) {

      this.logger.error('User is not defined, returning current Guest User', this.guestUser());
      observer.next(this.guestUser());
      return;
    }

    this._guestUserQueue.push(observer);
    if (this._gettingGuestUser) {

      return;
    }
    this._gettingGuestUser = true;

    let subscription: Subscription | undefined;
    const unsubscribe = () => subscription?.unsubscribe();
    subscription = this.getGuestUser()
      .subscribe(async (guestUser) => {

        if (0 < guestUser.accountNumber.length) {

          await firstValueFrom(this.setPoCount24hr(this.invitationGuid ?? ''));
        }

        this.guestUserSignal.set(guestUser);
        this._guestUserQueue.forEach(o => o.next(this.guestUser()));
        this._guestUserQueue = [];
        this._gettingGuestUser = false;

        unsubscribe();
      })
  });

  readonly poCount24Hrs = signal(0);
  readonly purchaseGuestLink = signal(<IPurchaseGuestLink>{ id: 0, enableCart: true })


  constructor(private readonly configurationService: ConfigurationService,
    private readonly dataService: DataService,
    private readonly userService: UserService,
    private readonly logger: NGXLogger
  ) {

    this.setUrls();
    this._destroyRef.onDestroy(() => this.onDestroy());

    effect(() => this.validateGuestUser(userService.showroomUser()), {allowSignalWrites: true})
  }


  getObjectAssignmentOrDefaultForObjectProp(objectProp: ObjectProp): ObjectAssignment | undefined {

    return this.currentEventDesign().getObjectAssignmentForObjectProp(objectProp) ??
      this.defaultEventDesign().getObjectAssignmentForObjectProp(objectProp);
  }


  private getGuestUser(): Observable<GuestUser> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          let url = `${this._showroomUsersUrl}/guestUser/${this.invitationGuid}`;

          return this.dataService.get<GuestUser>(url)
            .pipe(
              map((response: IGuestUser) => {

                this.logger.trace('Guest User', response);
                return new GuestUser(response);
              })
            )
        })
      );
  }


  /**
   * Count of Purchase Orders created for Invitation GUID in last 24 hours.
   * @param invitationGuid 
   * @returns 
   */
  setPoCount24hr(invitationGuid: string): Observable<number> {

    if (1 > invitationGuid.length) {

      return of(0);
    }

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._purchseOrdersUrl}/count/${this.invitationGuid}`;

          return this.dataService.get<number>(url, invitationGuid)
            .pipe(
              tap((response: number) => this.poCount24Hrs.set(response))
            )
        })
      );
  }


  async getPurchaseGuestLink(invitationGuid: string): Promise<IPurchaseGuestLink> {

    if (1 > invitationGuid?.length) {

      return <IPurchaseGuestLink>{ id: 0 };
    }

    await firstValueFrom(this.whenReady$);

    const url = `${this._purchaseGuestLinkUrl}/${invitationGuid}`;
    const purchaseGuestLink = await firstValueFrom(this.dataService.get<IPurchaseGuestLink>(url));

    this.purchaseGuestLink.set(deepCopy(purchaseGuestLink));

    return purchaseGuestLink;
  }


  /**
   * Venue with ImageProp and VenueEvent children, no deeper
   * @param invitationGuid 
   * @returns 
   */
  getVenue(invitationGuid: string): Observable<IVenue> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          let url = `${this._showroomGuestLinkUrl}/venues/${invitationGuid}`;

          return this.dataService.get<IVenue>(url)
            .pipe(
              tap((response: IVenue) => this._state.update(s => ({
                ...s,
                currentVenue: new Venue(response)
              })))
            )
        })
      );
  }


  private onDestroy(): void {

    this._subscriptions.forEach(s => s.unsubscribe());
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }


  setCurrentDesignImage(designImage: IDesignImage) {

    if (this._state().currentDesignImageId !== designImage.id) {

      this._state.update(s => ({
        ...s,
        currentDesignImageId: designImage.id
      }));
    }
  }


  setCurrentDesignObject(designObject: IDesignObject) {

    if (this._state().currentDesignObjectId !== designObject.id) {

      this._state.update(s => ({
        ...s,
        currentDesignObjectId: designObject.id
      }));
    }
  }


  setCurrentDesignVideo(designVideo: IDesignVideo) {

    if (this._state().currentDesignVideoId !== designVideo.id) {

      this._state.update(s => ({
        ...s,
        currentDesignVideoId: designVideo.id
      }));
    }
  }


  setCurrentEventDesign(eventDesignId: number): void {

    this._state.update(s => ({
      ...s,
      currentEventDesignId: eventDesignId
    }))
  }


  setCurrentImageAssignment(imageAssignment: ImageAssignment) {

    if (this._state().currentImageAssignmentId !== imageAssignment.id) {

      this._state.update(s => ({
        ...s,
        currentImageAssignmentId: imageAssignment.id
      }));
    }
  }


  setCurrentObjectAssignment(objectAssignment: IObjectAssignment) {

    if (this._state().currentObjectAssignmentId !== objectAssignment.id) {

      this._state.update(s => ({
        ...s,
        currentObjectAssignmentId: objectAssignment.id
      }));
    }
  }


  setCurrentVideoAssignment(videoAssignment: VideoAssignment) {

    if (this._state().currentVideoAssignmentId !== videoAssignment.id) {

      this._state.update(s => ({
        ...s,
        currentVideoAssignmentId: videoAssignment.id
      }));
    }
  }


  setCurrentImageProp(imagePropId: number) {

    if (this._state().currentImagePropId !== imagePropId) {

      this._state.update(s => ({
        ...s,
        currentImagePropId: imagePropId
      }));
    }
  }


  setCurrentObjectProp(objectPropId: number) {

    if (this._state().currentObjectPropId !== objectPropId) {

      this._state.update(s => ({
        ...s,
        currentObjectPropId: objectPropId
      }));
    }
  }


  setCurrentVideoProp(videoPropId: number) {

    if (this._state().currentVideoPropId !== videoPropId) {

      this._state.update(s => ({
        ...s,
        currentVideoPropId: videoPropId
      }));
    }
  }


  setCurrentVenueEvent(venueEventId: number): void {

    this._state.update(s => ({
      ...s,
      currentVenueEventId: venueEventId
    }))
  }


  setInvitationGuid(invitationGuid: string): Observable<boolean> {

    return this.whenReady$
      .pipe(
        switchMap(x => {

          const url = `${this._showroomGuestLinkUrl}/v/${invitationGuid}`;

          return this.dataService.get<boolean>(url)
            .pipe(
              tap((isValid: boolean) => {

                this.logger.trace('Is valid invitation guid', isValid)
                if (isValid) {

                  this.invitationGuid = invitationGuid;
                } else {

                  this.invitationGuid = undefined;
                }
                this.validateGuestUser(this.userService.showroomUser());
              })
            )
        })
      );
  }


  private async setPurchaseGuestLink(): Promise<void> {

    if (!this.invitationGuid || 1 > this.invitationGuid?.length) {

      return;
    }

    await firstValueFrom(this.whenReady$);

    const url = `${this._purchaseGuestLinkUrl}/${this.invitationGuid}`;
    const purchaseGuestLink = await firstValueFrom(this.dataService.get<IPurchaseGuestLink>(url));

    this.purchaseGuestLink.set(purchaseGuestLink);
  }


  private async setUrls(): Promise<void> {

    if (!this.configurationService.isReady) {

      await firstValueFrom(this.configurationService.whenReady$);
    }

    this._purchseOrdersUrl = `${URL_BASE.PURCHASE}/${PURCHASE_ENDPOINT.PurchaseOrder}`;
    this._purchaseGuestLinkUrl = `${URL_BASE.PURCHASE}/${PURCHASE_ENDPOINT.GuestLink}`;
    this._showroomGuestLinkUrl = `${URL_BASE.SHOWROOM}/${SHOWROOM_ENDPOINT.Guest}`;
    this._showroomUsersUrl = `${URL_BASE.SHOWROOM}/${SHOWROOM_ENDPOINT.User}`;
    this.isReady = true;
    this._urlsSetSource.next(true);
  }


  /**
   * If we have the required invitation guid and logged in user then try to instantiate Guest User.
   */
  private async validateGuestUser(showroomUser: ShowroomUser): Promise<void> {

    if (this.invitationGuid && this.userService.showroomUser().isValid) {

      await firstValueFrom(this.currentGuestUser$);
    } else {

      this.guestUserSignal.set(new GuestUser());
    }
  }


}
