<div class="flex flex-row w-full items-center group relative">
    
    @if (showroomItemType.Graphic === item().itemType) {

        <div class="w-[90px] h-[90px] mr-2 overflow-hidden">
            <img class="w-[90px] h-[90px] object-scale-down" [src]="item().designUrl" alt="Product image">
        </div>

    }
    <div class="flex flex-col w-full">

        <div class="overflow-hidden overflow-ellipsis whitespace-nowrap">
            {{item().assignmentName}}
        </div>

        @if (0 < item().propName.length && item().propName !== item().assignmentName) {
            
            <div class="overflow-hidden overflow-ellipsis whitespace-nowrap">
                {{item().propName}}
            </div>

        }

        @if (0 < item().priceOptionLabel.length) {
            
            <div>
                {{item().priceOptionLabel}}
            </div>
        
        }
        
    </div>
    
    <div class="flex flex-row transition-all duration-500 {{ isMobile ? '' : 'translate-x-6 group-hover:translate-x-0' }}">    
        <div class="mt-2 w-[100px] overflow-hidden">
            <!-- subtotal -->
            <div class="text-accent text-xl">
                $ {{ subTotal() | number: '1.2-2' }}
            </div>

            <!-- unit price -->
            <div>
                <span class="text-accent">
                    $ {{ item().price | number: '1.2-2' }} ea.
                </span>
            </div>
        </div>
        <div (click)="removeItem()" 
            class="cursor-pointer text-[24px] hover:text-accent transition-all duration-500" >
                <ng-icon name="bootstrapX" />
        </div>
    </div>

</div>