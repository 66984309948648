import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { DeleteMyDataComponent } from './pages/compliance/delete-my-data/delete-my-data.component';
import { PrivacyComponent } from './pages/compliance/privacy/privacy.component';
import { CatalogComponent } from './pages/catalog/catalog/catalog.component';
import { ProfileComponent } from './pages/myoptyx/profile/profile.component';
import { NotFoundComponent } from './pages/compliance/not-found/not-found.component';
import { CartComponent } from './pages/cart/cart/cart.component';
import { OrdersComponent } from './pages/order/orders/orders.component';
import { ProductsComponent } from './pages/catalog/products/products.component';
import { SearchResultsComponent } from './pages/search/search-results/search-results.component';
import { ProductDetailsComponent } from './pages/catalog/product-details/product-details.component';
import { OrderDetailsComponent } from './pages/order/order-details/order-details.component';
import { MAIN_ROUTES } from './layouts/config.main.routes';
import { LaunchARComponent } from './pages/showroom/launchAR/launchAR.component';

export const RoutePrefix = {
    STAGE: 'stage',
    CLIENT: 'designer',
    GUEST: 'guest',
    CUSTOMER: 'customer'
}

export const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home'
    },
    {
        path: 'home',
        children: MAIN_ROUTES
    },
    {
        path: 'showroom',
        loadChildren: () =>
            import('./layouts/config.showroom.routes')
                .then(routes => routes.SHOWROOM_ROUTES)
    },
    {
        path: `${RoutePrefix.CLIENT}`,
        loadChildren: () =>
            import('./layouts/config.client.routes')
                .then(routes => routes.CLIENT_ROUTES)
    },
    {
        path: `${RoutePrefix.STAGE}`,
        loadChildren: () =>
            import('./layouts/config.stage.routes')
                .then(routes => routes.STAGE_ROUTES)
    },
    {
        path: 'guest',
        loadChildren: () =>
            import('./layouts/config.guest.routes')
                .then(routes => routes.GUEST_ROUTES)
    },
    {
        path: 'customer',
        loadChildren: () =>
            import('./layouts/config.customer.routes')
                .then(routes => routes.CUSTOMER_ROUTES)
    },
    // {
    //     path: 'catalog',
    //     component: CatalogComponent
    // },
    // {
    //     path: 'products/:typeId',
    //     component: ProductsComponent
    // },
    // {
    //     path: 'product-details/:id',
    //     component: ProductDetailsComponent
    // },
    // {
    //     path: 'search/:searchTerm',
    //     component: SearchResultsComponent
    // },
    // {
    //     path: 'cart',
    //     component: CartComponent
    // },
    // {
    //     path: 'checkout/:orderId',
    //     loadComponent: () => import('./pages/order/order-checkout/order-checkout.component')
    //         .then((mod) => mod.OrderCheckoutComponent),
    //     canActivate: [
    //         MsalGuard
    //     ]
    // },
    // {
    //     path: 'orders',
    //     component: OrdersComponent,
    //     canActivate: [
    //         MsalGuard
    //     ]
    // },
    // {
    //     path: 'order-details/:id',
    //     component: OrderDetailsComponent,
    //     canActivate: [
    //         MsalGuard
    //     ]
    // },
    {
        path: 'unity',
        loadComponent: () => import('./pages/unity/unity.component')
            .then((mod) => mod.UnityComponent),
    },
    {
        path: 'launchAR',
        component: LaunchARComponent
    },
    {
        path: 'profile',
        component: ProfileComponent
    },
    {
        path: 'delete-my-data',
        component: DeleteMyDataComponent
    },
    {
        path: 'privacy',
        component: PrivacyComponent,
        title: 'My\u{A66A}ptyx privacy'
    },
    {   // loadComponent format
        path: 'tos',
        loadComponent: () => import('./pages/compliance/tos/tos.component')
            .then((mod) => mod.TosComponent),
        title: 'My\u{A66A}ptyx terms'
    },
    // {
    //     // Msal default auth redirect component for handling redirect after login
    //     path: 'auth',
    //     component: MsalRedirectComponent
    // }
    // Redirect bad routes to the home page
    {
        path: '**',
        redirectTo: 'home'
    }

];
