import { MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { NgxLoggerLevel } from 'ngx-logger';
import { ILogger } from 'projects/my-common/src/util/log';

/**
 * Controller or module endpoints
 */
export const PURCHASE_ENDPOINT = {
  Account: 'account',           // minimal API
  GuestLink: 'guest-link',      // minimal API 
  Invoice: 'orders',            // Controller
  PurchaseOrder: 'purchase-order', // minimal API 
  Subscription: 'subscription', // Controller
  User: 'user',                 // Controller
}
/**
 * Controller or module endpoints
 */
export const SHOWROOM_ENDPOINT = {
  Account: 'account',           // Controller
  Guest: 'guest',               // Controller
  Search: 'search',             // Controller
  Showroom: 'showroom',         // Controller
  User: 'user',                 // Controller
}

/**
   * An optional silentRequest object can be used to achieve silent SSO
   * between applications by providing a "loginHint" property (such as a username). For more, visit:
   * https://learn.microsoft.com/en-us/azure/active-directory/develop/msal-js-sso#sso-between-different-apps
   * If you do not receive the username claim in ID tokens, see also:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/FAQ.md#why-is-getaccountbyusername-returning-null-even-though-im-signed-in
   */
export const SILENT_REQUEST: MsalGuardConfiguration = {
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: ['api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.read', 'api://76eefa59-56cf-4d0a-b01d-617d8c6b50bb/showroom.write']
  }
};

export interface IApiConfig {
  uri: string;
  scopes: string[];
}

export enum Configuration { DEVELOP, STAGE, PRODUCTION }
export interface IEnvironment {
  configuration: Configuration
  stripePublishableKey: string
  b2cPolicies: {
    names: {
      signUpSignIn: string;
    }
    authorities: {
      signUpSignIn: {
        authority: string;
      };
    };
    //authorityDomain: string;
  }
  /**
   * Set your default interaction type for MSALGuard here. If you have any
   * additional scopes you want the user to consent upon login, add them here as well.
   */
  msalGuardConfig: MsalGuardConfiguration
  ngxLogLevel: NgxLoggerLevel
  logger?: ILogger
  /**
   * tslog comes with default log level 0: silly, 1: trace, 2: debug, 3: info, 4: warn, 5: error, 6: fatal.  
   */ 
  tslogLevel: number
  // Make sure api calls are only performed with the proper Auth or Anonymous else Guests will be challenged to login
  applyMatterportUpdates: boolean
  THREE_SCRIPT_PATH: string
  DEFAULT_MODEL_VIEWER_SRC: string
}
