/**
 * The classes
 * The aggregates for the data
 */
import { deepCopy } from "projects/mp-core/src/lib/util";
import {
    ActiconDefaultOptions, equals as equalsTuple, fields, getLogger, HorizontalClipMode, PLANE_RENDERER_DEFAUT_RENDER_ORDER,
    TextureFont, urlExists, Vector3Obj, Vector3Tuple, VerticalClipMode
} from "../..";
import {
    ClippingPlaneType, CrudState, EntityStatus, equalsClippingAssignments, equalsClippingPlanes, equalsObjectAssignmentMaterials, equalsObjectPriceOptions,
    IAssignmentBase, IClippingPlane, IClippingPlaneAssignment, IDesignImage, IDesignObject, IDesignObjectMaterial, IDesignVideo, IEventDesign, IGuestLink,
    IImageAssignment, IImageProp, IImagePropOptions, IImportableVideoSearchResult, IObjectAssignment, IObjectAssignmentMaterial, IObjectPriceOption, IObjectProp,
    IPositionAdjustment, IProp, isImportableVideoSearchResult, ISpaceProviderDefaultSetting, ISpaceProviderSetting, IVenue, IVenueEvent,
    IVideoAssignment, IVideoProp, IVideoPropOptions, PropClass, PropType, SpaceProvider
} from "..";


//
// Begin abstract
//

const propFields = fields<Prop>();
export abstract class Prop implements IProp {

    protected _type = PropType.PROP;
    public get type(): PropType {
        return this._type;
    }

    id = 0;
    venueId = 0;
    clippingPlanes: ClippingPlane[] = [];
    isActive = true;
    name = '';
    position = [0, 0, 0] as Vector3Tuple;
    positionAdjustments: PositionAdjustment[] = [];
    propClass = PropClass.Floating;
    rotation = [0, 0, 0] as Vector3Tuple;
    scale = [1, 1, 1] as Vector3Tuple;
    viewPositionId = '';


    public get positionObj(): Vector3Obj {
        return { x: this.position[0], y: this.position[1], z: this.position[2] };
    }
    public set positionObj(positionObj: Vector3Obj) {
        this.position[0] = positionObj.x;
        this.position[1] = positionObj.y;
        this.position[2] = positionObj.z;
    }
    public get rotationObj(): Vector3Obj {
        return { x: this.rotation[0], y: this.rotation[1], z: this.rotation[2] };
    }
    public set rotationObj(rotationObj: Vector3Obj) {
        this.rotation[0] = rotationObj.x;
        this.rotation[1] = rotationObj.y;
        this.rotation[2] = rotationObj.z;
    }
    public get scaleObj(): Vector3Obj {
        return { x: this.scale[0], y: this.scale[1], z: this.scale[2] };
    }
    public set scaleObj(scaleObj: Vector3Obj) {
        this.scale[0] = scaleObj.x;
        this.scale[1] = scaleObj.y;
        this.scale[2] = scaleObj.z;
    }


    constructor(prop?: IProp) {

        if (prop) {

            for (const positionAdjustment of prop.positionAdjustments) {

                this.positionAdjustments.push(new PositionAdjustment(positionAdjustment));
            }

            Object.keys(prop).forEach((key) => {

                if (`${propFields.positionAdjustments}` !== key) {

                    (this as any)[key as keyof IProp] = deepCopy(prop[key as keyof IProp]);
                }
            });
        }
    }


    removeAdjustment(target: IPositionAdjustment): IPositionAdjustment {

        let result = [target];
        const index = this.positionAdjustments.findIndex(pa => pa.id === target.id);
        if (- 1 < index) {

            result = this.positionAdjustments.splice(index, 1);
        };

        return result[0];
    }


    upsertAdjustment(target: PositionAdjustment): PositionAdjustment {

        let adjustment = new PositionAdjustment(target);
        let index = this.positionAdjustments.findIndex(pa => pa.id === target.id);
        if (-1 < index) {

            this.positionAdjustments[index] = adjustment;
        } else {

            this.positionAdjustments.push(adjustment);
        }
        this.positionAdjustments = [...this.positionAdjustments];

        return adjustment;
    }
}
export function equalsProp(p1: Prop, p2: Prop): boolean {

    return p1.type === p2.type
        && p1.id === p2.id
        && p1.venueId === p2.venueId
        && p1.isActive === p2.isActive
        && p1.name.trim() === p2.name.trim()
        && equalsTuple(p1.position, p2.position)
        && p1.propClass === p2.propClass
        && equalsTuple(p1.rotation, p2.rotation)
        && equalsTuple(p1.scale, p2.scale)
        && p1.viewPositionId === p2.viewPositionId
        && equalsClippingPlanes(p1.clippingPlanes, p2.clippingPlanes)
        && equalsAdjustments(p1.positionAdjustments, p2.positionAdjustments);
}

//
// End Abstract
//


export class AssignmentBase implements IAssignmentBase {

    id = 0;
    description = '';
    eventDesignId = 0;
    enableCart = false;
    enableInteraction = true;
    name = '';


    constructor(assignment?: IAssignmentBase) {

        if (assignment) {

            // Copy the properties into this
            Object.keys(assignment).forEach((key) => {

                (this as any)[key as keyof IAssignmentBase] = assignment[key as keyof IAssignmentBase];
            });
        }
    }
}
export function equalsAssignment(a1: AssignmentBase, a2: AssignmentBase): boolean {

    return a1.id === a2.id
        && a1.description.trim() === a2.description.trim()
        && a1.eventDesignId === a2.eventDesignId
        && a1.enableCart === a2.enableCart
        && a1.enableInteraction === a2.enableInteraction
        && a1.name.trim() === a2.name.trim();
}


export class ClippingPlane implements IClippingPlane {

    id = 0;
    parentPropId = 0;
    activatePropLevel = false;
    planeType = ClippingPlaneType.Square;
    position = [0, 0, 0] as Vector3Tuple;
    rotation = 0;
    scale = [0.2, 0.2, 0.2] as Vector3Tuple;

    crudState = CrudState.NONE;

    public get positionObj(): Vector3Obj {
        return { x: this.position[0], y: this.position[1], z: this.position[2] };
    }
    public set positionObj(positionObj: Vector3Obj) {
        this.position[0] = positionObj.x;
        this.position[1] = positionObj.y;
        this.position[2] = positionObj.z;
    }
    public get scaleObj(): Vector3Obj {
        return { x: this.scale[0], y: this.scale[1], z: this.scale[2] };
    }
    public set scaleObj(scaleObj: Vector3Obj) {
        this.scale[0] = scaleObj.x;
        this.scale[1] = scaleObj.y;
        this.scale[2] = scaleObj.z;
    }


    constructor(clippingPlane?: IClippingPlane) {

        if (clippingPlane) {

            // Copy the properties into this
            Object.keys(clippingPlane).forEach((key) => {

                (this as any)[key as keyof IClippingPlane] = deepCopy(clippingPlane[key as keyof IClippingPlane]);
            });
        }
    }
}


export class ClippingPlaneAssignment implements IClippingPlaneAssignment {

    id = 0;
    adjustmentId = 0;
    clippingPlaneId = 0;
    planeType = ClippingPlaneType.Square;
    position = [0, 0, 0] as Vector3Tuple;
    rotation = 0;
    scale = [1, 1, 1] as Vector3Tuple;
    stencilRef = 1;

    clippingPlane?: ClippingPlane;

    crudState = CrudState.NONE;

    /**
     * Has reference to Clipping Plane and matching clippingPlaneId
     */
    public get isAssigned(): boolean {

        return (this.clippingPlane?.id ?? Number.MIN_SAFE_INTEGER) === this.clippingPlaneId
            && CrudState.DELETED !== this.crudState;
    }


    public get positionObj(): Vector3Obj {
        return { x: this.position[0], y: this.position[1], z: this.position[2] };
    }
    public set positionObj(positionObj: Vector3Obj) {
        this.position[0] = positionObj.x;
        this.position[1] = positionObj.y;
        this.position[2] = positionObj.z;
    }
    public get scaleObj(): Vector3Obj {
        return { x: this.scale[0], y: this.scale[1], z: this.scale[2] };
    }
    public set scaleObj(scaleObj: Vector3Obj) {
        this.scale[0] = scaleObj.x;
        this.scale[1] = scaleObj.y;
        this.scale[2] = scaleObj.z;
    }

    constructor(clippingAssignment?: IClippingPlaneAssignment) {

        if (clippingAssignment) {

            let assignmentClass = clippingAssignment as ClippingPlaneAssignment;
            // Copy the properties into this
            Object.keys(clippingAssignment).forEach((key) => {

                (this as any)[key as keyof ClippingPlaneAssignment] = deepCopy(assignmentClass[key as keyof ClippingPlaneAssignment]);
            });
        }
    }
}


export class DesignImage implements IDesignImage {
    id = 0;
    eventDesignId = 0;
    description = '';
    fileName = '';
    fileSize = 0;
    uploadFileName = '';
    imageUrl = '';

    constructor(designImage?: IDesignImage) {

        if (designImage) {

            // Copy the properties into this
            Object.keys(designImage).forEach((key) => {

                (this as any)[key as keyof IDesignImage] = deepCopy(designImage[key as keyof IDesignImage]);
            });
        }
    }
}


/**
 * The glb file to associate with an Object Prop.
 * Offset, scale and target size vales.
 */
export class DesignObject implements IDesignObject {
    id = 0;
    eventDesignId = 0;
    description = '';
    fileName = '';
    fileSize = 0;
    uploadFileName = '';
    offset = [0, 0, 0] as Vector3Tuple;
    scale = [0, 0, 0] as Vector3Tuple;
    targetSize = [1, 1, 1] as Vector3Tuple;
    objectUrl = '';
    materials: IDesignObjectMaterial[] = [];


    public get offsetObj(): Vector3Obj {
        return { x: this.offset[0], y: this.offset[1], z: this.offset[2] };
    }
    public set offsetObj(offsetObj: Vector3Obj) {
        this.offset[0] = offsetObj.x;
        this.offset[1] = offsetObj.y;
        this.offset[2] = offsetObj.z;
    }
    public get scaleObj(): Vector3Obj {
        return { x: this.scale[0], y: this.scale[1], z: this.scale[2] };
    }
    public set scaleObj(scaleObj: Vector3Obj) {
        this.scale[0] = scaleObj.x;
        this.scale[1] = scaleObj.y;
        this.scale[2] = scaleObj.z;
    }
    public get targetSizeObj(): Vector3Obj {
        return { x: this.targetSize[0], y: this.targetSize[1], z: this.targetSize[2] };
    }

    constructor(designObject?: IDesignObject) {

        if (designObject) {

            // Copy the properties into this
            Object.keys(designObject).forEach((key) => {

                (this as any)[key as keyof IDesignObject] = deepCopy(designObject[key as keyof IDesignObject]);
            });
        }
    }
}
export function equalsDesignObject(do1: DesignObject, do2: DesignObject): boolean {

    return do1.id === do2.id
        && do1.eventDesignId === do2.eventDesignId
        && do1.description.trim() === do2.description.trim()
        && do1.fileName === do2.fileName
        && do1.uploadFileName === do2.uploadFileName
        && equalsTuple(do1.offset, do2.offset)
        && equalsTuple(do1.scale, do2.scale)
        && equalsTuple(do1.targetSize, do2.targetSize)
        && do1.objectUrl === do2.objectUrl;
}


export class DesignVideo implements IDesignVideo {
    id = 0;
    eventDesignId = 0;
    description = '';
    fileName = '';
    fileSize = 0;
    uploadFileName = '';
    videoUrl = '';
    hlsUrl = '';
    snapshotFileName = '';
    snapshotFileSize = 0;
    snapshotUrl = '';

    private _logger = getLogger();
    // https://stackoverflow.com/questions/42285032/how-do-i-import-shaka-player-into-an-angular-2-application
    private _shaka = require('../../../../../node_modules/shaka-player/dist/shaka-player.compiled');
    private _shakaPlayer: any;

    public get url(): string {

        return this.hlsUrl && 0 < this.hlsUrl.trim().length ? this.hlsUrl : this.videoUrl;
    }


    constructor(designVideo?: IDesignVideo | IImportableVideoSearchResult) {

        if (designVideo) {

            if (isImportableVideoSearchResult(designVideo)) {

                // From search results to support import video feature.
                this.id = designVideo.designVideoId;
                this.eventDesignId = designVideo.eventDesignId;
                this.fileName = designVideo.fileName;
                this.uploadFileName = designVideo.uploadFileName;
                this.videoUrl = designVideo.videoUrl;
                this.hlsUrl = designVideo.hlsUrl;
            } else {

                // Standard DesignVideo object
                // Copy the properties into this
                Object.keys(designVideo).forEach((key) => {

                    (this as any)[key as keyof IDesignVideo] = designVideo[key as keyof IDesignVideo];
                });
            }
        }
    }


    private _attachedVideo?: HTMLVideoElement;
    private async loadHls(video: HTMLVideoElement): Promise<boolean> {

        if (!this.hlsUrl || 5 > this.hlsUrl.length) {

            this._logger.trace(`hls url is undefined.`);
            return false;
        }
        if (!video) {

            this._logger.trace(`video element is undefined.`);
            return false;
        }
        if (this._attachedVideo && video !== this._attachedVideo && this._shakaPlayer) {

            await this._shakaPlayer.detach(this._attachedVideo);
            this._attachedVideo.src = '';
            this._attachedVideo.load();
            this._attachedVideo = undefined;
        }

        video.src = '';
        video.load();

        if (!await urlExists(this.hlsUrl)) {

            this._logger.warn(`invalid hls url: ${this.hlsUrl}. Encoding may still be in progress.`);
            return false;
        }

        if (!this._shakaPlayer) {

            this._shakaPlayer = new this._shaka.Player();
        }

        await this._shakaPlayer.attach(video);
        this._attachedVideo = video;

        // Attach player to the window to make it easy to access in the JS console.
        //(window as any).shakaPlayer = this._shakaPlayer;

        // Listen for error events.
        this._shakaPlayer.addEventListener('error', this.errorEventCallback);

        // Track video dimensions to set aspect downstream.
        const that = this;
        video.onloadedmetadata = function (e) {

            if (video) {

                const aspect = video.videoWidth / video.videoHeight;
                that._logger.trace(`video aspect: ${aspect}`);
            }
        };

        // Try to load a manifest.
        // This is an asynchronous process.
        try {

            await this._shakaPlayer.load(this.hlsUrl);
        } catch (e) {

            // onError is executed if the asynchronous load fails.
            this.onError(e);
            return false;
        }

        return true;
    }


    private readonly errorEventCallback = this.onErrorEvent.bind(this);
    private onErrorEvent(event: any) {
        // Extract the shaka.util.Error object from the event.
        this.onError(event.detail);
    }


    private onError(error: any) {
        // Log the error.
        this._logger.error('Error code', error.code, 'object', error);
    }


    /**
     * Don't set video source until after initialization so the UI can render before trying to download a possibly large video.
     * @returns 
     */
    async setVideoSource(video: HTMLVideoElement): Promise<void> {

        // Try hls streaming first.
        if (video && await this.loadHls(video)) {

            return;
        }

        video.src = this.videoUrl;
        video.load();
    }


    private releaseResources() {

        if (this._shakaPlayer) {

            if (this._attachedVideo) {

                this._attachedVideo.src = '';
                this._attachedVideo.load();
                this._shakaPlayer.detach(this._attachedVideo);
            }

            this._shakaPlayer.removeEventListener('error', this.errorEventCallback);
            this._shakaPlayer.destroy();
            this._shakaPlayer = null;
        }
    }


    dispose() {

        this.releaseResources();
    }

}


const eventDesignFields = fields<EventDesign>();
export class EventDesign implements IEventDesign {

    id = 0;
    venueEventId = 0;
    name = '';
    status = EntityStatus.Active;
    isDefault = false;
    designImages = [] as DesignImage[];
    designObjects = [] as DesignObject[];
    designVideos = [] as DesignVideo[];
    imageAssignments = [] as ImageAssignment[];
    imagePropOptions = [] as ImagePropOptions[];
    objectAssignments = [] as ObjectAssignment[];
    videoAssignments = [] as VideoAssignment[];
    videoPropOptions = [] as IVideoPropOptions[];

    constructor(eventDesign?: IEventDesign) {

        if (eventDesign) {

            // Copy the properties into this
            Object.keys(eventDesign).forEach((key) => {

                switch (key) {
                    case `${eventDesignFields.designImages}`:
                        for (const designImage of eventDesign.designImages) {

                            this.designImages.push(new DesignImage(designImage));
                        }
                        break;
                    case `${eventDesignFields.designObjects}`:
                        for (const designObject of eventDesign.designObjects) {

                            this.designObjects.push(new DesignObject(designObject));
                        }
                        break;
                    case `${eventDesignFields.designVideos}`:
                        for (const designVideo of eventDesign.designVideos) {

                            this.designVideos.push(new DesignVideo(designVideo));
                        }
                        break;
                    case `${eventDesignFields.objectAssignments}`:
                        for (const assignment of eventDesign.objectAssignments) {

                            this.objectAssignments.push(new ObjectAssignment(assignment));
                        }
                        break;
                    case `${eventDesignFields.imageAssignments}`:
                        for (const assignment of eventDesign.imageAssignments) {

                            this.imageAssignments.push(new ImageAssignment(assignment));
                        }
                        break;
                    case `${eventDesignFields.imagePropOptions}`:
                        for (const propOptions of eventDesign.imagePropOptions) {

                            this.imagePropOptions.push(new ImagePropOptions(propOptions));
                        }
                        break;
                    case `${eventDesignFields.videoAssignments}`:
                        for (const assignment of eventDesign.videoAssignments) {

                            this.videoAssignments.push(new VideoAssignment(assignment));
                        }
                        break;
                    case `${eventDesignFields.videoPropOptions}`:
                        this.videoPropOptions = [...eventDesign.videoPropOptions];
                        break;
                    default:
                        (this as any)[key as keyof IEventDesign] = eventDesign[key as keyof IEventDesign];
                        break;
                }
            });
        }
    }


    getDesignImageForAssignment(imageAssignment: ImageAssignment): DesignImage | undefined {

        return this.designImages.find(di => di.id === imageAssignment.designImageId);
    }


    getDesignObjectForAssignment(objectAssignment: ObjectAssignment): DesignObject | undefined {

        return this.designObjects.find(_do => _do.id === objectAssignment.designObjectId);
    }


    getDesignVideoForAssignment(videoAssignment: VideoAssignment): DesignVideo | undefined {

        return this.designVideos.find(dv => dv.id === videoAssignment.designVideoId);
    }


    getImageAssignmentForDesignImage(designImageId: number, imagePropId: number): ImageAssignment | undefined {

        return this.imageAssignments.find(ia => ia.designImageId === designImageId && ia.imagePropId === imagePropId);
    }


    getObjectAssignmentForObjectProp(objectProp: ObjectProp): ObjectAssignment | undefined {

        return this.objectAssignments.find(oa => oa.objectPropId === objectProp.id);
    }


    getVideoAssignmentForDesignVideo(designVideoId: number, videoPropId: number): VideoAssignment | undefined {

        return this.videoAssignments.find(va => va.designVideoId === designVideoId && va.videoPropId === videoPropId);
    }


    isNewObjectAssignmentValid(objectAssignment: ObjectAssignment): boolean {

        // If Prop is already assigned to the Design Object then we can't assign it again.
        const existingAssignment = this.objectAssignments.find(ia =>
            ia.designObjectId === objectAssignment.designObjectId
            && ia.objectPropId === objectAssignment.objectPropId);
        if (existingAssignment) {

            console.error('assignment already exists', existingAssignment);
            return false;
        }

        // If Design Object doesn't exist then assignment is invalid
        const designObject = this.designObjects.find(_do => _do.id === objectAssignment.designObjectId);
        if (!designObject) {

            console.error('Design object does not exist', objectAssignment);
            return false;
        }

        return true;
    }


    removeDesignImage(target: IDesignImage): IDesignImage {
        
        let result = [target];
        const index = this.designImages.findIndex(va => va.id === target.id);
        if (- 1 < index) {

            result = this.designImages.splice(index, 1);
        };

        return result[0];
    }


    removeDesignObject(target: IDesignObject): IDesignObject {

        let result = [target];
        const index = this.designObjects.findIndex(va => va.id === target.id);
        if (- 1 < index) {

            result = this.designObjects.splice(index, 1);
        };

        return result[0];
    }


    removeDesignVideo(target: IDesignVideo): IDesignVideo {

        let result = [target];
        const index = this.designVideos.findIndex(dv => dv.id === target.id);
        if (- 1 < index) {

            result = this.designVideos.splice(index, 1);
        };

        return result[0];
    }


    removeImageAssignment(target: IImageAssignment): IImageAssignment {

        let result = [target];
        const index = this.imageAssignments.findIndex(ia => ia.id === target.id);
        if (- 1 < index) {

            result = this.imageAssignments.splice(index, 1);
        };

        return result[0];
    }


    removeImageAssignmentsForProp(target: IImageProp): void {

        this.imageAssignments = this.imageAssignments.filter(ia => ia.imagePropId !== target.id);
    }


    removeObjectAssignment(target: IObjectAssignment): IObjectAssignment {

        let result = [target];
        const index = this.objectAssignments.findIndex(oa => oa.id === target.id);
        if (- 1 < index) {

            result = this.objectAssignments.splice(index, 1);
        };

        return result[0];
    }


    removeObjectAssignmentsForProp(target: IObjectProp): void {

        this.objectAssignments = this.objectAssignments.filter(oa => oa.objectPropId !== target.id);
    }


    removeVideoAssignment(target: IVideoAssignment): IVideoAssignment {

        let result = [target];
        const index = this.videoAssignments.findIndex(va => va.id === target.id);
        if (- 1 < index) {

            result = this.videoAssignments.splice(index, 1);
        };

        return result[0];
    }


    removeVideoAssignmentsForProp(target: IVideoProp): void {

        this.videoAssignments = this.videoAssignments.filter(va => va.videoPropId !== target.id);
    }


    upsertDesignImage(target: IDesignImage): DesignImage {

        let index = this.designImages.findIndex(di => di.id === target.id);
        if (-1 < index) {

            this.designImages[index] = new DesignImage(target);
            return this.designImages[index];
        }

        index = this.designImages.push(new DesignImage(target));
        return this.designImages[index - 1];
    }


    upsertDesignObject(target: IDesignObject): DesignObject {

        let index = this.designObjects.findIndex(_do => _do.id === target.id);
        if (-1 < index) {

            this.designObjects[index] = new DesignObject(target);
            return this.designObjects[index];
        }

        index = this.designObjects.push(new DesignObject(target));
        return this.designObjects[index - 1];
    }


    upsertDesignVideo(target: IDesignVideo): DesignVideo {

        let index = this.designVideos.findIndex(dv => dv.id === target.id);
        if (-1 < index) {

            this.designVideos[index] = new DesignVideo(target);
            return this.designVideos[index];
        }

        index = this.designVideos.push(new DesignVideo(target));
        return this.designVideos[index - 1];
    }


    upsertImageAssignment(target: IImageAssignment): ImageAssignment {

        let assignment = new ImageAssignment(target);
        let index = this.imageAssignments.findIndex(ia => ia.id === target.id);
        if (-1 < index) {

            this.imageAssignments[index] = assignment;
        } else {

            this.imageAssignments.push(assignment);
        }
        this.imageAssignments = [...this.imageAssignments];

        return assignment;
    }


    upsertObjectAssignment(target: IObjectAssignment): ObjectAssignment {

        let assignment = new ObjectAssignment(target);
        let index = this.objectAssignments.findIndex(oa => oa.id === target.id);
        if (-1 < index) {

            this.objectAssignments[index] = assignment;
        } else {

            this.objectAssignments.push(assignment);
        }
        this.objectAssignments = [...this.objectAssignments];

        return assignment;
    }


    upsertVideoAssignment(target: IVideoAssignment): IVideoAssignment {

        let index = this.videoAssignments.findIndex(va => va.id === target.id);
        if (-1 < index) {

            this.videoAssignments[index] = target;
            return this.videoAssignments[index];
        }

        index = this.videoAssignments.push(target);
        return this.videoAssignments[index - 1];
    }
}


export class ImageAssignment extends AssignmentBase implements IImageAssignment {

    designImageId = 0;
    imagePropId = 0;
    font = TextureFont.Impact;
    fontSize = 250;
    text = 'Text';
    price = 0.0;
    stretchToFit = true;
    suppressSidebarImage = false;


    constructor(assignment?: IImageAssignment) {
        super(assignment);

        if (assignment) {

            // Copy the properties into this
            Object.keys(assignment).forEach((key) => {

                (this as any)[key as keyof IImageAssignment] = assignment[key as keyof IImageAssignment];
            });
        }
    }
}
export function equalsImageAssignment(ia1: ImageAssignment, ia2: ImageAssignment): boolean {

    return equalsAssignment(ia1, ia2)
        && ia1.designImageId === ia2.designImageId
        && ia1.imagePropId === ia2.imagePropId
        && ia1.font === ia2.font
        && ia1.fontSize === ia2.fontSize
        && ia1.text.trim() === ia2.text.trim()
        && ia1.price === ia2.price
        && ia1.stretchToFit === ia2.stretchToFit
        && ia1.suppressSidebarImage === ia2.suppressSidebarImage;
}


/**
 * Objectify Image Prop data from the server
 */
export class ImageProp extends Prop implements IImageProp {

    aspect = 1;
    maskFileName = '';
    maskUrl = '';


    constructor(imageProp?: IImageProp) {
        super(imageProp);

        this._type = PropType.IMAGE;

        // Handle the properties not handles by base class
        if (imageProp) {

            this.aspect = imageProp.aspect;
            this.maskFileName = imageProp.maskFileName;
            this.maskUrl = imageProp.maskUrl;
        }
    }
}
export function equalsImageProp(ip1: ImageProp, ip2: ImageProp): boolean {

    return ip1.aspect === ip2.aspect
        && ip1.maskFileName === ip2.maskFileName
        && ip1.maskUrl === ip2.maskUrl
        && equalsProp(ip1, ip2);
}


export class ImagePropOptions implements IImagePropOptions {
    id = 0
    backgroundColor = '#FFFFFF00'
    eventDesignId = 0
    imagePropId = 0
    controlsPosition = ActiconDefaultOptions.alignment
    controlsMargin = ActiconDefaultOptions.margin
    controlsZ = ActiconDefaultOptions.z
    interactionDistance = ActiconDefaultOptions.interactionDistance

    constructor(imagePropOptions?: IImagePropOptions) {

        if (imagePropOptions) {

            // Copy the properties into this
            Object.keys(imagePropOptions).forEach((key) => {

                (this as any)[key as keyof IImagePropOptions] = imagePropOptions[key as keyof IImagePropOptions];
            });
        }
    }
}


export class ObjectAssignment extends AssignmentBase implements IObjectAssignment {

    designObjectId = 0;
    objectPropId = 0;
    position = [0, 0, 0] as Vector3Tuple;
    rotation = [0, 0, 0] as Vector3Tuple;
    scale = [1, 1, 1] as Vector3Tuple;
    materials = [] as IObjectAssignmentMaterial[];
    priceOptions = [] as IObjectPriceOption[];


    public get positionObj(): Vector3Obj {
        return { x: this.position[0], y: this.position[1], z: this.position[2] };
    }
    public set positionObj(positionObj: Vector3Obj) {
        this.position[0] = positionObj.x;
        this.position[1] = positionObj.y;
        this.position[2] = positionObj.z;
    }
    public get rotationObj(): Vector3Obj {
        return { x: this.rotation[0], y: this.rotation[1], z: this.rotation[2] };
    }
    public set rotationObj(rotationObj: Vector3Obj) {
        this.rotation[0] = rotationObj.x;
        this.rotation[1] = rotationObj.y;
        this.rotation[2] = rotationObj.z;
    }
    public get scaleObj(): Vector3Obj {
        return { x: this.scale[0], y: this.scale[1], z: this.scale[2] };
    }
    public set scaleObj(scaleObj: Vector3Obj) {
        this.scale[0] = scaleObj.x;
        this.scale[1] = scaleObj.y;
        this.scale[2] = scaleObj.z;
    }


    constructor(assignment?: IObjectAssignment) {
        super(assignment);
        
        if (assignment) {

            // Copy the properties into this
            Object.keys(assignment).forEach((key) => {

                (this as any)[key as keyof IObjectAssignment] = deepCopy(assignment[key as keyof IObjectAssignment]);
            });
        }
    }
}
export function equalsObjectAssignment(oa1: ObjectAssignment, oa2: ObjectAssignment, includeMaterials = false): boolean {

    // TODO: add Price Option comparisons
    const result = equalsAssignment(oa1, oa2)
        && oa1.designObjectId === oa2.designObjectId
        && oa1.objectPropId === oa2.objectPropId
        && equalsTuple(oa1.position, oa2.position)
        && equalsTuple(oa1.rotation, oa2.rotation)
        && equalsTuple(oa1.scale, oa2.scale)
        && equalsObjectPriceOptions(oa1.priceOptions, oa2.priceOptions);

    return includeMaterials ? result && equalsObjectAssignmentMaterials(oa1.materials, oa2.materials) : result;
}


export class ObjectProp extends Prop implements IObjectProp {

    constructor(objectProp?: IObjectProp) {
        super(objectProp);

        this._type = PropType.OBJECT;
    }
}
export function equalsObjectProp(op1: ObjectProp, op2: ObjectProp): boolean {

    return equalsProp(op1, op2);
}


const adjustmentFields = fields<PositionAdjustment>();
export class PositionAdjustment implements IPositionAdjustment {

    id = 0;
    adjust = [0, 0, 0] as Vector3Tuple;
    clippingAssignments = [] as ClippingPlaneAssignment[]
    disableDepth = false;
    hide = false;
    horizontalMaskMode = HorizontalClipMode.NONE;
    horizontalMaskWidth = 1;
    horizontalMaskXOffset = 0;
    parentPropId = 0;
    positionId = '';
    renderOrder = PLANE_RENDERER_DEFAUT_RENDER_ORDER;
    rotate = [0, 0, 0] as Vector3Tuple;
    scale = [0, 0, 0] as Vector3Tuple;
    stencilRef = 1;
    verticalMaskHeight = 1;
    verticalMaskMode = VerticalClipMode.NONE;
    verticalMaskYOffset = 0;
    maskFileName = '';
    maskUrl = '';

    // Support file upload
    file!: File;

    // Class specific
    crudState = CrudState.NONE;

    public get adjustObj(): Vector3Obj {
        return { x: this.adjust[0], y: this.adjust[1], z: this.adjust[2] };
    }
    public get rotateObj(): Vector3Obj {
        return { x: this.rotate[0], y: this.rotate[1], z: this.rotate[2] };
    }
    public get scaleObj(): Vector3Obj {
        return { x: this.scale[0], y: this.scale[1], z: this.scale[2] };
    }

    constructor(positionAdjustment?: IPositionAdjustment) {

        if (positionAdjustment) {

            // Copy the properties into this
            Object.keys(positionAdjustment).forEach((key) => {

                if (`${adjustmentFields.clippingAssignments}` !== key) {

                    if (Array.isArray(positionAdjustment[key as keyof IPositionAdjustment])) {

                        (this as any)[key as keyof IPositionAdjustment] = deepCopy(positionAdjustment[key as keyof IPositionAdjustment]);
                    } else {

                        (this as any)[key as keyof IPositionAdjustment] = positionAdjustment[key as keyof IPositionAdjustment];
                    }
                }
            });

            if (positionAdjustment.clippingAssignments
                && 0 < positionAdjustment.clippingAssignments.length) {

                for (const clippingAssignment of positionAdjustment.clippingAssignments) {

                    this.clippingAssignments.push(new ClippingPlaneAssignment(clippingAssignment));
                }
            }
        }
    }


    /**
     * Excludes clipping assignments
     * @param positionAdjustment 
     */
    copyTo(positionAdjustment: PositionAdjustment): void {

        Object.keys(this).forEach((key) => {

            if (`${adjustmentFields.clippingAssignments}` !== key) {

                if (Array.isArray(this[key as keyof IPositionAdjustment])) {

                    (positionAdjustment as any)[key as keyof IPositionAdjustment] = deepCopy(this[key as keyof IPositionAdjustment]);
                } else {

                    (positionAdjustment as any)[key as keyof IPositionAdjustment] = this[key as keyof IPositionAdjustment];
                }
            }
        });

        positionAdjustment.crudState = this.crudState;
    }
}
//
// Position adjustment utils
//
export function equalsAdjustment(pa1: IPositionAdjustment, pa2: IPositionAdjustment): boolean {

    return pa1.id === pa2.id
        && pa1.hide === pa2.hide
        && pa1.disableDepth === pa2.disableDepth
        && pa1.horizontalMaskMode === pa2.horizontalMaskMode
        && pa1.horizontalMaskWidth === pa2.horizontalMaskWidth
        && pa1.horizontalMaskXOffset == pa2.horizontalMaskXOffset
        && pa1.maskFileName === pa2.maskFileName
        && pa1.maskUrl === pa2.maskUrl
        && pa1.parentPropId === pa2.parentPropId
        && pa1.positionId === pa2.positionId
        && pa1.renderOrder === pa2.renderOrder
        && pa1.stencilRef === pa2.stencilRef
        && pa1.verticalMaskHeight === pa2.verticalMaskHeight
        && pa1.verticalMaskMode === pa2.verticalMaskMode
        && pa1.verticalMaskYOffset === pa2.verticalMaskYOffset
        && equalsTuple(pa1.adjust, pa2.adjust)
        && equalsTuple(pa1.rotate, pa2.rotate)
        && equalsTuple(pa1.scale, pa2.scale)
        && equalsClippingAssignments(pa1.clippingAssignments, pa2.clippingAssignments);
}

export function equalsAdjustments(adjustments1: IPositionAdjustment[], adjustments2: IPositionAdjustment[]) {

    let isEqual = adjustments1.length === adjustments2.length;

    if (isEqual && 0 < adjustments1.length) {

        for (const adjustment1 of adjustments1) {

            const adjustment2 = adjustments2.find(cpa => cpa.id === adjustment1.id);
            if (!adjustment2 || !equalsAdjustment(adjustment1, adjustment2)) {

                return false;
            }
        }
    }

    return isEqual;
}
//
// End position adjustment utils
//


const venueFields = fields<Venue>();
export class Venue implements IVenue {

    id = 0;
    accountId = 0;
    imageProps: ImageProp[] = [];
    name = '';
    objectProps: ObjectProp[] = [];
    spaceId = '';
    spaceProviderId = SpaceProvider.MATTERPORT;
    spaceProviderDefaultSettings: ISpaceProviderDefaultSetting[] = [];
    spaceProviderSettings: ISpaceProviderSetting[] = [];
    status = EntityStatus.Active;
    venueEvents: VenueEvent[] = [];
    videoProps: VideoProp[] = [];


    constructor(venue?: IVenue) {

        if (!venue) {

            return;
        }

        // Copy the properties into this
        Object.keys(venue).forEach((key) => {

            switch (key) {
                case `${venueFields.imageProps}`:
                    for (const imageProp of venue.imageProps) {

                        this.imageProps.push(new ImageProp(imageProp));
                    }
                    break;
                case `${venueFields.objectProps}`:
                    for (const objectProp of venue.objectProps) {

                        this.objectProps.push(new ObjectProp(objectProp));
                    }
                    break;
                case `${venueFields.spaceProviderDefaultSettings}`:
                    this.spaceProviderDefaultSettings = [...venue.spaceProviderDefaultSettings];
                    break;
                case `${venueFields.spaceProviderSettings}`:
                    this.spaceProviderSettings = [...venue.spaceProviderSettings];
                    break;
                case `${venueFields.venueEvents}`:
                    for (const venueEvent of venue.venueEvents) {

                        this.venueEvents.push(new VenueEvent(venueEvent));
                    }
                    break;
                case `${venueFields.videoProps}`:
                    for (const videoProp of venue.videoProps) {

                        this.videoProps.push(new VideoProp(videoProp));
                    }
                    break;
                default:
                    (this as any)[key as keyof IVenue] = venue[key as keyof IVenue];
                    break;
            }
        });
    }


    getEventDesign(eventDesignId: number): EventDesign | undefined {

        let eventDesign: EventDesign | undefined;
        for (const venueEvent of this.venueEvents) {

            eventDesign = venueEvent.getEventDesign(eventDesignId);
            if (eventDesign) {

                return eventDesign;
            }
        }

        return eventDesign;
    }


    getVenueEvent(venueEventId: number): VenueEvent | undefined {

        return this.venueEvents.find(ve => ve.id === venueEventId);
    }


    removeImageProp(target: IImageProp): IImageProp {

        let result = [target];
        if (target.venueId === this.id) {

            const imagePropIndex = this.imageProps.findIndex(ip => ip.id === target.id);
            if (-1 < imagePropIndex) {

                result = this.imageProps.splice(imagePropIndex, 1);
            }

            for (const venueEvent of this.venueEvents) {
    
                venueEvent.removeImageAssignments(target);
            }
        }

        return result[0];
    }


    removeImagePropAdjustment(target: IPositionAdjustment): IPositionAdjustment {

        let result = [target];
        for (let imageProp of this.imageProps) {

            const adjustmentIndex = imageProp.positionAdjustments.findIndex(pa => pa.id === target.id);
            if (-1 < adjustmentIndex) {

                result = imageProp.positionAdjustments.splice(adjustmentIndex, 1);
                break;
            }
        }

        return result[0];
    }


    removeObjectProp(target: IObjectProp): IObjectProp {

        let result = [target];
        if (target.venueId === this.id) {

            const objectPropIndex = this.objectProps.findIndex(op => op.id === target.id);
            if (-1 < objectPropIndex) {

                result = this.objectProps.splice(objectPropIndex, 1);
            }

            for (const venueEvent of this.venueEvents) {
    
                venueEvent.removeObjectAssignments(target);
            }
        }

        return result[0];
    }


    removeVideoProp(target: IVideoProp): IVideoProp {

        let result = [target];
        if (target.venueId === this.id) {

            const videoPropIndex = this.videoProps.findIndex(ip => ip.id === target.id);
            if (-1 < videoPropIndex) {

                result = this.videoProps.splice(videoPropIndex, 1);
            }

            for (const venueEvent of this.venueEvents) {
    
                venueEvent.removeVideoAssignments(target);
            }
        }

        return result[0];
    }


    removeVideoPropAdjustment(target: IPositionAdjustment): IPositionAdjustment {

        let result = [target];
        for (let videoProp of this.videoProps) {

            const adjustmentIndex = videoProp.positionAdjustments.findIndex(pa => pa.id === target.id);
            if (-1 < adjustmentIndex) {

                result = videoProp.positionAdjustments.splice(adjustmentIndex, 1);
                break;
            }
        }

        return result[0];
    }


    removeVenueEvent(target: IVenueEvent): IVenueEvent {

        let result = [target];
        if (target.venueId === this.id) {

            const deletedVenueEventIndex = this.venueEvents.findIndex(ve => ve.id === target.id);
            if (-1 < deletedVenueEventIndex) {

                result = this.venueEvents.splice(deletedVenueEventIndex, 1);
            }
        }

        return result[0];
    }


    upsertImagePropAdjustment(target: IPositionAdjustment): PositionAdjustment {

        const adjustment = new PositionAdjustment(target);
        const imageProp = this.imageProps.find(ip => ip.id === adjustment.parentPropId);
        if (imageProp) {
            
            const existingAdjustmentIndex = imageProp.positionAdjustments.findIndex(pa => pa.id === target.id);
            if (-1 < existingAdjustmentIndex) {

                imageProp.positionAdjustments[existingAdjustmentIndex] = adjustment;
            } else {

                imageProp.positionAdjustments.push(adjustment);
            }
        }

        return adjustment;
    }


    upsertVenueEvent(target: IVenueEvent): VenueEvent {

        const venueEvent = new VenueEvent(target);
        const existingEventIndex = this.venueEvents.findIndex(ve => ve.id === target.id);
        if (-1 < existingEventIndex) {

            this.venueEvents[existingEventIndex] = venueEvent;
        } else {

            this.venueEvents.push(venueEvent);
        }

        return venueEvent;
    }


    upsertVideoPropAdjustment(target: IPositionAdjustment): PositionAdjustment {

        const adjustment = new PositionAdjustment(target);
        const videoProp = this.videoProps.find(ip => ip.id === adjustment.parentPropId);
        if (videoProp) {
            
            const existingAdjustmentIndex = videoProp.positionAdjustments.findIndex(pa => pa.id === target.id);
            if (-1 < existingAdjustmentIndex) {

                videoProp.positionAdjustments[existingAdjustmentIndex] = adjustment;
            } else {

                videoProp.positionAdjustments.push(adjustment);
            }
        }

        return adjustment;
    }
}


const venueEventFields = fields<VenueEvent>();
export class VenueEvent implements IVenueEvent {

    id = 0;
    venueId = 0;
    status = EntityStatus.Active;
    isDefault = false;
    name = '';
    eventDesigns: EventDesign[] = [];
    guestLinks: IGuestLink[] = [];


    constructor(venueEvent?: IVenueEvent) {

        if (!venueEvent) {

            return;
        }

        // Copy the properties into this
        Object.keys(venueEvent).forEach((key) => {

            switch (key) {
                case `${venueEventFields.eventDesigns}`:
                    for (const eventDesign of venueEvent.eventDesigns) {

                        this.eventDesigns.push(new EventDesign(eventDesign));
                    }
                    break;
                case `${venueEventFields.guestLinks}`:
                    this.guestLinks = [...venueEvent.guestLinks];
                    break;
                default:
                    (this as any)[key as keyof IVenueEvent] = venueEvent[key as keyof IVenueEvent];
                    break;
            }
        });
    }


    getEventDesign(eventDesignId: number): EventDesign | undefined {

        return this.eventDesigns.find(ed => ed.id === eventDesignId);
    }


    removeEventDesign(target: IEventDesign): IEventDesign {

        let result = [target];
        const eventDesignIndex = this.eventDesigns.findIndex(ed => ed.id = target.id);
        if (-1 < eventDesignIndex) {

            result = this.eventDesigns.splice(eventDesignIndex, 1);
        }

        return result[0];
    }


    removeImageAssignments(target: IImageProp): void {

        for (const eventDesign of this.eventDesigns) {

            eventDesign.removeImageAssignmentsForProp(target);
        }
    }


    removeObjectAssignments(target: IObjectProp): void {

        for (const eventDesign of this.eventDesigns) {

            eventDesign.removeObjectAssignmentsForProp(target);
        }
    }


    removeVideoAssignments(target: IVideoProp): void {

        for (const eventDesign of this.eventDesigns) {

            eventDesign.removeVideoAssignmentsForProp(target);
        }
    }


    upsertEventDesign(target: IEventDesign): EventDesign {

        const existingDesignIndex = this.eventDesigns.findIndex(ed => ed.id === target.id);
        const eventDesign = new EventDesign(target);
        if (-1 < existingDesignIndex) {

            this.eventDesigns[existingDesignIndex] = eventDesign;
        } else {

            this.eventDesigns.push(eventDesign);
        }

        return eventDesign;
    }


    upsertGuestLink(target: IGuestLink): IGuestLink {

        const existingLinkIndex = this.guestLinks.findIndex(gl => gl.id === target.id);
        if (-1 < existingLinkIndex) {

            this.guestLinks[existingLinkIndex] = target;
        } else {

            this.guestLinks = [target, ...this.guestLinks]
        }

        return target;
    }
}


export class VideoAssignment extends AssignmentBase implements IVideoAssignment {

    designVideoId = 0;
    videoPropId = 0;
    suppressSidebarVideo = false;


    constructor(assignment?: IVideoAssignment) {
        super(assignment);

        if (assignment) {

            // Copy the properties into this
            Object.keys(assignment).forEach((key) => {

                (this as any)[key as keyof IVideoAssignment] = assignment[key as keyof IVideoAssignment];
            });
        }
    }
}
export function equalsVideoAssignment(va1: VideoAssignment, va2: VideoAssignment): boolean {

    return equalsAssignment(va1, va2)
        && va1.designVideoId === va2.designVideoId
        && va1.videoPropId === va2.videoPropId
        && va1.suppressSidebarVideo === va2.suppressSidebarVideo;
}


/**
 * Objectify Video Prop data from the server
 */
export class VideoProp extends Prop implements IVideoProp {

    aspect = 1;
    maskFileName = '';
    maskUrl = '';


    constructor(videoProp?: IVideoProp) {
        super(videoProp);

        this._type = PropType.VIDEO;

        if (videoProp) {

            this.aspect = videoProp.aspect;
            this.maskFileName = videoProp.maskFileName;
            this.maskUrl = videoProp.maskUrl;
        }
    }
}
export function equalsVideoProp(vp1: VideoProp, vp2: VideoProp): boolean {

    return vp1.aspect === vp2.aspect
        && vp1.maskFileName === vp2.maskFileName
        && vp1.maskUrl === vp2.maskUrl
        && equalsProp(vp1, vp2);
}


export class VideoPropOptions implements IVideoPropOptions {
    id = 0
    audioDistance = 3
    autoPlay = false
    autoPlayDistance = 6
    backgroundColor = '#000000FF'
    controlsMargin = ActiconDefaultOptions.margin
    controlsPosition = ActiconDefaultOptions.alignment
    controlsZ = ActiconDefaultOptions.z
    eventDesignId = 0
    interactionDistance = ActiconDefaultOptions.interactionDistance
    isOrchestrationParent = false
    loop = false
    orchestrationParentId = 0
    videoPropId = 0

    public get isChild(): boolean {

        return !this.isOrchestrationParent && 0 < this.orchestrationParentId;
    }

    constructor(videoPropOptions?: IVideoPropOptions) {

        if (videoPropOptions) {

            // Copy the properties into this
            Object.keys(videoPropOptions).forEach((key) => {

                (this as any)[key as keyof IVideoPropOptions] = videoPropOptions[key as keyof IVideoPropOptions];
            });
        }
    }
}

